import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from 'components/Dialog';

import PasswordForm from 'pages/Profile/PasswordForm';

import * as authActions from 'redux/HomeOwner/auth/actions';
import { t } from 'i18next';

const ChangePassword = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { changingPassword, refetch } = useSelector((state) => state.auth);

  useEffect(() => {
    if (refetch) {
      dispatch(authActions.resetRefetch());
      handleClose();
    }
  }, [dispatch, handleClose, refetch]);

  const handleSubmit = (values) => {
    dispatch(authActions.changePassword(values));
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={"Change password"}
      fullWidth={true}
      content={
        <PasswordForm loading={changingPassword} handleSubmit={handleSubmit} />
      }
    />
  );
};

export default ChangePassword;
