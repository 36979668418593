import * as common from 'redux/common';

const parseRows = (data, page = 1, pageSize = 10) => {
  return data.map((ele, idx) => {
    return {
      ...ele,
      no: common.getSerialNumber(idx, page, pageSize),
    };
  });
};

export const getAllUsersNotifications = (d) => {
  let newPage = d?.page - 1;

  if (d?.data?.notifications?.length === 0 && d?.page > 1) {
    return {
      page: newPage,
      pageSize: d?.pageSize,
      count: d?.count,
      columns: [],
      unreadCount: d?.unreadCount,
      totalCount: d?.notifications?.total,
      rows: parseRows(d.data?.notifications, d?.page, d?.pageSize) || [],
    };
  } else {
    return {
      page: d?.page,
      pageSize: d?.pageSize,
      count: d?.count,
      columns: [],
      unreadCount: d?.unreadCount,
      totalCount: d?.notifications?.total,
      rows: parseRows(d.data?.notifications, d?.page, d?.pageSize) || [],
    };
  }
};
