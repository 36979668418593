//---------------GET ALL USERS NOTIFICATIONS-------------------
export const GET_ALL_USERS_NOTIFICATIONS_REQUEST =
  'GET_ALL_USERS_NOTIFICATIONS_REQUEST';
export const GET_ALL_USERS_NOTIFICATIONS_SUCCESS =
  'GET_ALL_USERS_NOTIFICATIONS_SUCCESS';
export const GET_ALL_USERS_NOTIFICATIONS_FAIL =
  'GET_ALL_USERS_NOTIFICATIONS_FAIL';

//---------------UPDATE USER'S NOTIFICATIONS-------------------
export const UPDATE_USER_NOTIFICATION_REQUEST =
  'UPDATE_USER_NOTIFICATION_REQUEST';
export const UPDATE_USER_NOTIFICATION_SUCCESS =
  'UPDATE_USER_NOTIFICATION_SUCCESS';
export const UPDATE_USER_NOTIFICATION_FAIL = 'UPDATE_USER_NOTIFICATION_FAIL';

//--------------UPDATE PAGE ORDER------------------------
export const UPDATE_USER_NOTIFY_PAGE = 'UPDATE_USER_NOTIFY_PAGE';

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
