import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { privateRoutes } from 'routes';

import { useTheme } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Toolbar, IconButton, Box, Menu, MenuItem } from '@mui/material';
import HumbergerIcon from 'assets/svg/hamburger_Menu.svg';
import MuiAppBar from '@mui/material/AppBar';
import Button from 'components/Button';
import Person2Icon from '@mui/icons-material/Person2';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Typography from 'components/Typography';
import AccountAvatar from 'components/PrivateLayout/AccountAvatar';
import NotifyListData from 'components/PrivateLayout/NotifyListData';
import NotificationBell from 'components/PrivateLayout/NotificationBell';
import MarkAsReadContent from 'components/PrivateLayout/MarkAsReadContent';

import * as notifyActions from 'redux/notifications/actions';
import useResponsiveBreakpoints from 'utils';

const drawerWidth = 310;
const closeDrawer = 56;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
  borderBottom: '1px solid #fff',
  margin: '30px 0px 0px 14px',
  borderRadius: '10px',
  background: '#FFF',
  right: '25px',
  ...(open && {
    width: open
      ? `calc(100% - ${drawerWidth}px)`
      : `calc(100% - ${closeDrawer}px)`,
    marginLeft: open ? `${drawerWidth}px` : `${closeDrawer}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const settings = [
  {
    id: 1,
    title: 'My profile',
    icon: <Person2Icon />,
  },
  {
    id: 2,
    title: 'Change password',
    icon: <LockIcon />,
  },
  {
    id: 3,
    title: 'Logout',
    icon: <LogoutIcon />,
  },
];

const MyAppBar = ({
  name,
  initials,
  src,
  open,
  handleDrawer,
  handleMenu,
  responsiveAppbar,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notifyId, setNotifyId] = React.useState('');
  const { t } = useTranslation();
  const location = useLocation();
  const { isSM, isLG } = useResponsiveBreakpoints();
  const theme = useTheme();

  const { ownerUser } = useSelector((state) => state.auth);
  const { notifyAll, fetchUpdating } = useSelector(
    (state) => state.notification
  );

  // const { page } = notifyAll;
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [notitfy, setNotify] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenNotifyMenu = (e) => {
    setNotify(e.currentTarget);
  };

  const handleCloseNotifyBar = () => {
    setNotify(null);
  };

  const handleCloseUserMenu = (_, menu) => {
    setAnchorElUser(null);
    setTimeout(() => {
      handleMenu(menu);
    }, 100);
  };

  const handleUpdateMarkNotification = () => {
    let data = {
      status: true,
      notificationId: notifyId,
    };
    dispatch(notifyActions?.updatedMarkAsReadNotifications(data, 'all'));
  };

  const handleViewPage = (event, item) => {
    event.stopPropagation();
    if (item?.notificationType === 'Appointment') {
      navigate('/appointment-request');
    }
    if (item?.notificationType === 'Proposal') {
      navigate(`/proposals/accept-proposal/${item?.sourceId}`);
    }
    if (item?.notificationType === 'Invoice') {
      navigate(`/invoices/paybill/invoice/${item?.sourceId}`);
    }
    setNotify(null);
  };

  const handleClickView = (item) => {
    setNotifyId(item?.id);
    let data = {
      status: true,
      notificationId: item?.id,
    };
    dispatch(notifyActions?.updatedMarkAsReadNotifications(data, 'all'));
  };

  React.useEffect(() => {
    dispatch(notifyActions?.getAllUsersNotification(1));
  }, [dispatch, fetchUpdating]);

  const getHeaderName = (pathname) => {
    if (pathname.includes('/projects/viewProject/')) {
      return 'View project';
    } else if (pathname.includes('/proposals/accept-proposal/')) {
      return 'Accept proposal';
    } else if (
      pathname.includes('/invoices/paybill/invoice/') &&
      !pathname.includes('/invoice/pay-online') &&
      !pathname.includes('/invoice/payment')
    ) {
      return 'View invoice';
    } else if (
      pathname.includes('/invoices/paybill') &&
      pathname.includes('/invoice/pay-online')
    ) {
      return 'Invoice pay online';
    } else if (
      pathname.includes('/invoices/paybill') &&
      pathname.includes('/invoice/payment')
    ) {
      return 'Invoice payment success';
    } else if (
      pathname.includes('/appointment-request') &&
      !pathname.includes('/appointment-request/customer/view-request')
    ) {
      return 'Request appointment';
    } else if (
      pathname.includes('/appointment-request/customer/view-request')
    ) {
      return 'Customer request appointment';
    } else if (pathname.includes('/projects/compare/')) {
      return 'Compare proposal';
    } else if (pathname.includes('/viewProposal/')) {
      return 'View proposal';
    }

    const route = privateRoutes?.find(
      (item) => item?.path === pathname || item.path + '/' === pathname
    );
    return route?.headerName || 'My projects';
  };

  const headerName = getHeaderName(location.pathname);

  return (
    <AppBar
      className={`${
        open ? 'open_header_bar' : 'homeOwner_close_header_bar'
      } hw-resSidebar`}
      position="fixed"
      open={open}
    >
      <Toolbar>
        {responsiveAppbar ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawer}
              edge="start"
              sx={{
                position: 'absolute',
                left: open ? 16 : 72,
              }}
            >
              <img
                className={open ? '' : 'toggle_rotate'}
                src={HumbergerIcon}
                alt="Toggle Icon"
                style={{ padding: 1 }}
                width={'30px'}
                height={'30px'}
              />
            </IconButton>
          </>
        ) : (
          <IconButton
            color="inherit"
            aria-label="homeOwner open drawer"
            onClick={handleDrawer}
            edge="start"
            sx={{
              position: 'absolute',
              left: open ? 16 : 72,
            }}
          >
            <KeyboardArrowLeftIcon className={open ? '' : 'toggle_rotate'} />
          </IconButton>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100vw',
            ml: '2rem',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: {
                xs: 'center',
                lg: 'left',
              },
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              fontSize={isSM ? '20px' : '16px'}
              fontWeight={500}
              textAlign={isLG ? 'left' : 'center'}
            >
              {headerName || 'Home'}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Box sx={{ display: 'flex' }}>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                className={isLG ? 'notify_bell_btn' : 'hw-notify_bell_btn_res'}
                onClick={handleOpenNotifyMenu}
                sx={{
                  mr: 2,
                  p: 0,
                }}
              >
                <NotificationBell
                  className={isLG ? 'bell_btn_icon' : 'hw-res_bell_icon'}
                  count={
                    ownerUser?.unreadNotificationCount > 0
                      ? ownerUser?.unreadNotificationCount
                      : 0
                  }
                />
              </IconButton>

              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, borderRadius: 0 }}
              >
                <AccountAvatar name={name} src={src} initials={initials} />
              </IconButton>
            </Box>

            <Menu
              className={isSM ? 'noti_menu_show' : 'hw-res_noti_menu_show'}
              PaperProps={{
                sx: {
                  width: '100%',
                  padding: '28px 10px 10px 10px',
                  margin: '44px 0px 0px 0px',
                  maxWidth: isSM ? '595px' : 'auto',
                  minWidth: isSM ? '595px' : 'auto',
                  boxShadow: '0px 2px 15px 0px rgba(0, 0, 0, 0.10)',
                  height: 'auto',
                  borderRadius: '20px',
                  overflow: 'hidden',
                  border: `1px solid ${theme?.palette?.lightBorder?.main}`,
                  width: !isSM ? 'calc(100% - 10px)' : '100%',
                },
              }}
              anchorEl={notitfy}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(notitfy)}
              onClose={handleCloseNotifyBar}
              MenuListProps={{
                disablePadding: true,
              }}
            >
              <MenuItem className="notify_bar_menu">
                <Box className="notify_main_content">
                  <Box className="notify_settings_header">
                    <Typography
                      color={'#4B4D5F'}
                      fontWeight={500}
                      letterSpacing={'0.00938em'}
                      sx={{
                        fontSize: {
                          xs: '14px !important',
                          sm: '16px !important',
                        },
                      }}
                    >
                      Notifications
                    </Typography>

                    <MarkAsReadContent
                      handleUpdateMarkNotification={
                        handleUpdateMarkNotification
                      }
                      notifyCount={ownerUser?.unreadNotificationCount}
                    />
                  </Box>
                </Box>
              </MenuItem>
              <NotifyListData
                ownerUser={ownerUser}
                notifyAll={notifyAll}
                handleClickView={handleClickView}
                handleViewPage={handleViewPage}
              />
              {/* <Box m={'1rem 0rem'}>
                <Button
                  variant="outlined"
                  className="see_all_rct_act"
                  sx={{
                    fontSize: {
                      xs: '14px !important',
                      sm: '16px !important',
                    },
                    '&:hover': {
                      border: 'none',
                      backgroundColor: 'transparent',
                    },
                  }}
                  type="button"
                  onClick={handleUpdateMarkNotification}
                  fullWidth
                  disabled={ownerUser?.unreadNotificationCount === 0}
                >
                  See all recent activities
                </Button>
              </Box> */}
            </Menu>

            <Menu
              sx={{ mt: isLG ? '45px' : '38px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.id}
                  onClick={(e) => handleCloseUserMenu(e, setting)}
                >
                  <Box alignItems="center" display="flex">
                    <Box sx={{ marginLeft: 1 }}>
                      <Typography sx={{ fontSize: { xs: '13px', sm: '16px' } }}>
                        {setting.title}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
