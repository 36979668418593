import * as React from 'react';
import { TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Typography';
import useResponsiveBreakpoints from 'utils';

export default function MyTextField(props) {
  const { t } = useTranslation();
  const { isSM } = useResponsiveBreakpoints();

  const internalSx = {
    width: props.isWidth && '100%',
    '& .MuiInputBase-input::placeholder': {
      fontSize: isSM ? '16px' : '13px !important',
    },
    '& .MuiInputBase-input': {
      fontSize: isSM ? '16px' : '13px !important',
    },
  };
  const combinedSx = [internalSx, props?.sx];

  const handleKeyDown = (e) => {
    if (
      props?.type === 'number' &&
      (e.key === 'ArrowUp' ||
        e.key === 'ArrowDown' ||
        e.key === 'e' ||
        e.key === 'E' ||
        e.key === '-' ||
        e.key === '+')
    ) {
      e.preventDefault();
    }
  };

  if (props?.label) {
    return (
      <Box mt={props?.isMargin ? 1 : 2}>
        <Typography
          variant="body2"
          align="left"
          color={props?.labelColor}
          sx={{
            margin: '3px',
            fontFamily: props?.fontFamily,
            fontWeight: props?.fontWeight,
            fontSize: { xs: '13px', sm: '16px' },
          }}
        >
          {props?.id === 'sectionTitle' ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent:
                  props?.pageType === 'customPage' && 'space-between',
              }}
            >
              {props?.label?.props?.children?.map((e, index) => (
                <div key={index}>{e}</div>
              ))}
            </div>
          ) : (
            props?.label
          )}

          {props?.required && (
            <Box component="span" ml={1} color={props?.requiredColor}>
              *
            </Box>
          )}
        </Typography>
        <TextField
          InputLabelProps={{ shrink: true }}
          {...props}
          onKeyDown={handleKeyDown}
          sx={combinedSx}
        />
      </Box>
    );
  }

  return <TextField {...props}  onKeyDown={handleKeyDown} sx={combinedSx} />;
}
