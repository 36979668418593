import React, { useRef, useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Box, Typography, styled } from '@mui/material';
import TooltipIcon from 'assets/svg/tooltip-icon.svg';
import ThemeContext from 'themes/ThemeContext';



const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#f5f5f5', // Light background color
    color: '#000', // Text color
    fontSize: '14px', // Adjust font size
    fontWeight: '500',
    fontFamily : '',
    borderRadius: '8px', // Rounded corners
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Subtle shadow
    padding: '10px 15px', // Padding inside tooltip
    wordWrap: 'break-word',   // Ensures long words break and wrap
    wordBreak: 'break-word',  // Forces break on long words
    whiteSpace: 'normal',     // Ensures wrapping instead of overflow
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#f5f5f5', // Match background color
  },
}));




const EllipsisTooltip = ({
  text,
  width,
  fontFamily,
  fontSize,
  fontWeight,
  color,
  mb,
  ml,
  typClassName,
  children,
  tooltipProps,
  type,
  toolTipWidth,
  textTransform,
  padding,
  textAlign,
}) => {
  const textRef = useRef(null);
  const usertheme = useContext(ThemeContext);
  const [isEllipsed, setIsEllipsed] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const { scrollWidth, clientWidth } = textRef.current;
      setIsEllipsed(scrollWidth > clientWidth);
    }
  }, [text,fontSize,width]);

  const toolTipTitle = (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
       <img src={TooltipIcon} alt="tooltip icon" />
      <Typography
        color={usertheme?.lightTheme?.palette?.gray?.main}
        fontSize={12}
        fontWeight={500}
        // textTransform={type === 'proposal' ? 'inherit' : 'capitalize'}
        ml={'4px'}
        // sx={{background : props.background && props.background }}
      >
        {text}
      </Typography>
    </Box>
  );
// Clone the child element and forward the ref correctly
const childWithTooltip = React.isValidElement(children)
? React.cloneElement(children, {
    ref: textRef, // Attach the ref to the child
    style: {
      ...children.props.style,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: children.props.style?.display || 'inline-block',
    },
  })
: children;
  if(type==="singleLineText"){
    if (!text) return children;
    return (
      <CustomTooltip title={isEllipsed?toolTipTitle:''} arrow {...tooltipProps}  disableHoverListener={!isEllipsed}>
        {childWithTooltip}
      </CustomTooltip>
    );
  }

  return (
    <CustomTooltip
      title={isEllipsed ? toolTipTitle : ''}
      disableHoverListener={!isEllipsed}
      arrow
    >
      <Typography
        ref={textRef}
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: width,
          fontFamily: fontFamily,
          fontSize: fontSize,
          fontWeight: fontWeight,
          color: color,
          mb: mb,
          ml: ml,
          width:toolTipWidth,
          textTransform:textTransform,
          padding:padding,
          textAlign:textAlign,
        }}
        className={typClassName ? typClassName : ''}
      >
        {text}
      </Typography>
    </CustomTooltip>
  );
};

export default EllipsisTooltip;
