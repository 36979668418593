import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TooltipIcon from 'assets/svg/tooltip-icon.svg';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useParams, useNavigate } from 'react-router-dom';
import LeftLogo from 'assets/svg/logo.svg';
import Logo from 'components/Logo';
import LogoutIcon from 'assets/new-theme-icons/svg/logout_icon.svg';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ThemeContext from 'themes/ThemeContext';

// import { getInitialsFromStr } from 'utils';
// import { Collapse } from '@mui/material';
// import i18n from 'i18n';
// import { updateLocale } from '../../i18n';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useDispatch } from 'react-redux';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'center',
}));

const ResSidebar = ({
  open,
  openDropDown,
  sidebar,
  selected,
  selectId,
  setSelected,
  logo,
  companyName,
  handleNavigate,
  handleToggle,
  handleOpenModal,
  ...props
}) => {
  const usertheme = useContext(ThemeContext);
  const theme = useTheme();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const ToBeStyledTooltip = ({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ tooltip: className, popper: className }}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: '[data-popper-arrow]',
            },
          },
        ],
      }}
    />
  );

  const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      maxWidth: 250,
      padding: '10px',
      margin: '5px',
      borderRadius: 10,
      border: `1px solid ${usertheme?.lightTheme?.palette?.darkBlue?.main}`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFFFFF',
      fontSize: 20,
      '&:before': {
        content: '""',
        backgroundColor: '#FFFFFF',
        border: `1px solid ${usertheme?.lightTheme?.palette?.darkBlue?.main}`,
        transform: 'rotate(45deg)',
      },
    },
  }));

  const selectedCSS = {
    background: usertheme?.homeOwnerTheme?.palette?.white?.main,
    color: usertheme?.homeOwnerTheme?.palette?.black?.main,
    fontWeight: 600,
  };

  useEffect(() => {
    let location = window?.location?.href;
    if (location?.includes('templates')) {
      let findObj = sidebar?.find((ele) => ele?.dropdown === true);
      let findId = findObj?.subRoutes?.find((item) => {
        return item?.page_id === Number(id);
      });
      setSelected(findId);
    }
  }, [sidebar, setSelected, id, navigate]);

  const handleClickLogo = () => {
    navigate(`/projects`);
  };

  // const lngs = {
  //   en: { nativeName: 'English' },
  //   es: { nativeName: 'Spanish' },
  // };

  return (
    <>
      <DrawerHeader className="Db_hd_homeOwner" sx={{ background: '#FFF' }}>
        <Box mt={2} pt={2} pb={2}>
          <img
            src={LeftLogo}
            alt="Company-Logo"
            className="company-logo"
            onClick={handleClickLogo}
          />
        </Box>
      </DrawerHeader>
      <Divider />

      {/* ------------------------------LANGUAGE CHANGE BUTTON/HOMEOWNER------------------------------------ */}

      {/* {Object.keys(lngs).map((lng) => (
        <button
          key={lng}
          style={{
            fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal',
          }}
          type="submit"
          onClick={() => updateLocale(lng)}
        >
          {lngs[lng].nativeName}
        </button>
      ))}    
      
      {/* ------------------------------------------------------------------ */}

      <List
        sx={{ pt: 1 }}
        className={'side-bar_main_homeOwner'}
      >
        {/* <List sx={{ pt: 0 }} > */}
        {sidebar.map((ele) => {
          const selectedItem = selected?.id === ele.id;
          const toolTipTitle = (
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img src={TooltipIcon} alt="tooltip icon" />
              <Typography
                color={usertheme?.lightTheme?.palette?.gray?.main}
                fontSize={14}
                fontWeight={500}
              >
                {ele.title}
              </Typography>
            </Box>
          );

          if (ele?.isBottom) {
            return (
              <ListItem
                key={ele?.id}
                disablePadding
                onClick={() => handleNavigate(ele)}
                className={
                  selectedItem
                    ? 'list-active bottom-setting'
                    : 'non-active bottom-setting'
                }
              >
                {!open ? (
                  <StyledTooltip
                    title={toolTipTitle}
                    placement="right-end"
                    arrow
                  >
                    <ListItemButton>
                      <ListItemIcon
                        sx={{
                          minWidth: 32,
                          color: selectedItem
                            ? usertheme?.homeOwnerTheme?.palette?.black?.main
                            : usertheme?.homeOwnerTheme?.palette?.white?.main,
                          padding: open ? 0 : '4px 0px',
                        }}
                      >
                        <img src={ele.icon} alt="" width={20} />
                      </ListItemIcon>

                      {open && <ListItemText primary={ele.title} />}
                    </ListItemButton>
                  </StyledTooltip>
                ) : (
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 32,
                        color: selectedItem
                          ? usertheme?.homeOwnerTheme?.palette?.black?.main
                          : usertheme?.homeOwnerTheme?.palette?.white?.main,
                        padding: open ? 0 : '4px 0px',
                      }}
                    >
                      <img src={ele.icon} alt="" width={20} />
                    </ListItemIcon>

                    {open && <ListItemText primary={ele.title} />}
                  </ListItemButton>
                )}
              </ListItem>
            );
          }

          return (
            <React.Fragment key={ele.id}>
              {ele?.toggle === false ? (
                <ListItem
                  disablePadding
                  onClick={handleToggle}
                  className={selectedItem ? 'list-active' : 'non-active'}
                  sx={
                    selectedItem
                      ? selectedCSS
                      : { color: '#FFF', fontWeight: 600 }
                  }
                >
                  <ListItemButton>
                    <ListItemIcon
                      sx={{
                        minWidth: 32,
                        color: '#FFF',
                        padding: openDropDown ? 0 : '4px 0px',
                      }}
                    >
                      <img src={ele.icon} alt="" width={20} />
                    </ListItemIcon>

                    <ListItemText primary={ele.title} />

                    {ele?.subRoutes &&
                      (openDropDown ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
                  </ListItemButton>
                </ListItem>
              ) : (
                <ListItem
                  disablePadding
                  onClick={() => handleNavigate(ele)}
                  className={selectedItem ? 'list-active' : 'non-active'}
                >
                  {!open ? (
                    <StyledTooltip
                      title={toolTipTitle}
                      placement="right-end"
                      arrow
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            minWidth: 32,
                            color: selectedItem
                              ? usertheme?.homeOwnerTheme?.palette?.black?.main
                              : usertheme?.homeOwnerTheme?.palette?.white?.main,
                            padding: open ? 0 : '4px 0px',
                          }}
                        >
                          <img src={ele.icon} alt="" width={20} />
                        </ListItemIcon>

                        {open && <ListItemText primary={ele.title} />}
                      </ListItemButton>
                    </StyledTooltip>
                  ) : (
                    <ListItemButton>
                      <ListItemIcon
                        sx={{
                          minWidth: 32,
                          color: selectedItem
                            ? usertheme?.homeOwnerTheme?.palette?.black?.main
                            : usertheme?.homeOwnerTheme?.palette?.white?.main,
                          padding: open ? 0 : '4px 0px',
                        }}
                      >
                        <img src={ele.icon} alt="" width={20} />
                      </ListItemIcon>

                      {open && <ListItemText primary={ele.title} />}
                    </ListItemButton>
                  )}
                </ListItem>
              )}
            </React.Fragment>
          );
        })}
      </List>
      <Button
        type="button"
        className="logout_btn_side"
        onClick={handleOpenModal}
        sx={{
          minWidth: '20px !important',
          padding: '0 0 20px 0px',
          justifyContent: 'flex-start',
          marginLeft: open ? '30px' : '25px',
        }}
      >
        <Box>
            <Box display={'flex'}>
              <Logo
                src={LogoutIcon}
                alt={'Logout icon'}
                with={20}
                height={20}
              />
              <Typography
                fontWeight={500}
                color={theme?.palette?.dangerStatus?.main}
                ml={1}
              >
                Logout
              </Typography>
            </Box>
        </Box>
      </Button>
    </>
  );
};

export default ResSidebar;
