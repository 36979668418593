import * as constants from 'redux/HomeOwner/myProposals/constants';

const initialState = {
  getting: false,
  getProposal: null,
  status: false,
  fetchStatus: false,
  gettingSelectedProposals: false,
  selectedProposals: [],
  gettingThreadId: false,
  threadId: null,
  runId: null,
  gettingAI: false,
  AI_res: null,
  checking: false,
  checkStatus: null,
};

export default function myProposalsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_PROPOSAL_BY_PROPOSAL_ID_REQUEST:
      return {
        ...state,
        getting: true,
      };

    case constants.GET_PROPOSAL_BY_PROPOSAL_ID_SUCCESS:
      return {
        ...state,
        getting: false,
        getProposal: payload,
        fetchStatus: false,
      };

    case constants.GET_PROPOSAL_BY_PROPOSAL_ID_FAIL:
      return {
        ...state,
        getting: false,
      };

    case constants.PROPOSAL_ACCEPT_OR_DECLINE_REQUEST:
      return {
        ...state,
        status: true,
        fetchStatus: false,
      };

    case constants.PROPOSAL_ACCEPT_OR_DECLINE_SUCCESS:
      return {
        ...state,
        status: false,
        fetchStatus: true,
      };

    case constants.PROPOSAL_ACCEPT_OR_DECLINE_FAIL:
      return {
        ...state,
        status: false,
        fetchStatus: false,
      };

    case constants.GET_SELECTED_PROPOSALS_REQUEST:
      return {
        ...state,
        gettingSelectedProposals: true,
      };

    case constants.GET_SELECTED_PROPOSALS_SUCCESS:
      return {
        ...state,
        gettingSelectedProposals: false,
        selectedProposals: payload,
        fetchStatus: false,
      };

    case constants.GET_SELECTED_PROPOSALS_FAIL:
      return {
        ...state,
        gettingSelectedProposals: false,
      };

    case constants.GET_THREAD_ID_REQUEST:
      return {
        ...state,
        gettingThreadId: true,
      };

    case constants.GET_THREAD_ID_SUCCESS:
      return {
        ...state,
        gettingThreadId: false,
        threadId: payload.threadId,
        runId: payload.runId,
      };

    case constants.GET_THREAD_ID_FAIL:
      return {
        ...state,
        gettingThreadId: false,
      };

    case constants.QUERY_PROPOSAL_BY_AI_REQUEST:
      return {
        ...state,
        gettingAI: true,
      };

    case constants.QUERY_PROPOSAL_BY_AI_SUCCESS:
      return {
        ...state,
        gettingAI: false,
        AI_res: payload,
      };

    case constants.QUERY_PROPOSAL_BY_AI_FAIL:
      return {
        ...state,
        gettingAI: false,
      };

    case constants.AI_CHECK_STATUS_REQUEST:
      return {
        ...state,
        checking: true,
      };

    case constants.AI_CHECK_STATUS_SUCCESS:
      return {
        ...state,
        checking: false,
        checkStatus: payload,
      };

    case constants.AI_CHECK_STATUS_FAIL:
      return {
        ...state,
        checking: false,
      };

    case constants.RESET_REFETCH:
      return {
        ...state,
        fetchStatus: false,
      };

    case constants.RESET_AI_STATUS:
      return{
        ...state,
        AI_res: {
          ...state.AI_res,
          starStatus: false,
        }
      }
    default:
      return state;
  }
}
