import * as constants from 'redux/proposals/constants';

// import * as payloads from 'page/proposals/payloads';

// import * as constants from 'redux/proposals/constants'
const initialState = {
  loading: true,
  creating: false,
  updating: false,
  deleting: false,
  deleted: false,
  refetch: false,
  proposals: [],
  proposal: null,
  sideNav: null,
  proposalForm: null,
  updateSettings: false,
  titleProgress: 0,
  logoProgress: 0,
  titleUploading: false,
  titleUploaded: false,
  logoUploading: false,
  logoUploaded: false,
  savingPage: false,
  savingProposal: false,
  sending: false,
  generating: false,
  pdf: false,
  getPage: null,
  loadPage: false,
  deletingPage: false,
  creatingSection: false,
  QuotePageload: false,
  updatingQuote: false,
  creatingItem: false,
  deletingItem: false,
  deleteProposalSection: null,
  loadingTemp: false,
  listTemp: [],
  loadingNotes: false,
  notes: [],
  creatingNote: false,
  deletingNote: false,
  attachProgress: 0,
  attachUploading: false,
  attachUploaded: false,
  createSection: false,
  createItem: false,
  customImageProgress: 0,
  imageUploading: false,
  imageUploaded: false,
  customInd: null,
  deletingCustomSection: false,
  deletingCustomItem: false,
  loadingCount: false,
  rejectLoading: false,
  loadingList: false,
  clientArray: [],
  deletingCustomSectionImg: false,
  gettingAllTax: false,
  gettingAllTaxValue: null,
  updatingView: false,
  fetchingView: false,
  fetchingTemp: false,
  updatingCustomer: false,
  fetchingCustomer: false,
  gettingProposal: false,
  deletingCustomer: false,
  fetchClient: false,
  customerProposal: {},
  proposalSent: false,
  getting: false,
  gettingCustomerAllProposal: [],
  markView: false,
  notFound: null,
};

export default function proposalsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_PROPOSALS_REQUEST:
    case constants.GET_PROPOSAL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case constants.GET_PROPOSALS_SUCCESS:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: false,
        deleting: false,
        fetchingTemp: false,
        fetchingCustomer: false,
        fetchClient: false,
        proposal: null,
        proposals: payload,
      };

    case constants.GET_PROPOSALS_FAIL:
    case constants.GET_PROPOSAL_FAIL:
      return {
        ...state,
        loading: false,
        fetchingTemp: false,
        proposal: null,
      };

    case constants.CREATE_PROPOSAL_REQUEST:
    case constants.CREATE_PAGE_REQUEST:
      return {
        ...state,
        creating: true,
      };

    case constants.CREATE_PROPOSAL_SUCCESS:
      return {
        ...state,
        creating: false,
        refetch: true,
        proposal: payload,
      };

    case constants.CREATE_PROPOSAL_FAIL:
    case constants.CREATE_PAGE_FAIL:
      return {
        ...state,
        creating: false,
      };

    case constants.GET_PROPOSAL_SUCCESS:
      return {
        ...state,
        loading: false,
        proposal: null,
        fetchingTemp: false,
        fetchingCustomer: false,
        fetchClient: false,
        sideNav: payload?.sideNav,
        proposalForm: payload?.proposalForm,
      };

    case constants.UPDATE_PROPOSAL_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      const keyName = payload.keyName;

      let value = payload.value;
      if (keyName === 'ProposalCustomPage') {
        const index = copyProposalForm?.ProposalCustomPage.findIndex(
          (e) => e?.id === value?.id
        );
        if (index > -1) {
          const copyProposalCustomPage = [
            ...copyProposalForm.ProposalCustomPage,
          ];
          copyProposalCustomPage[index] = payload?.value;
          value = copyProposalCustomPage;
        }
      }

      if (keyName === 'ProposalQuoteDetail') {
        const index = copyProposalForm?.ProposalQuoteDetail?.findIndex(
          (e) => e?.id === value?.id
        );

        if (index > -1) {
          const copyProposalQuoteDetails = [
            ...copyProposalForm.ProposalQuoteDetail,
          ];

          copyProposalQuoteDetails[index] = payload?.value;
          value = copyProposalQuoteDetails;
        }
      }

      return {
        ...state,
        updating: true,
        fetchingTemp: false,
        proposalForm: {
          ...state.proposalForm,
          [keyName]: value,
        },
      };
    }

    case constants.UPDATE_PROPOSAL_SUCCESS: {
      const keyName = payload.keyName;
      if (keyName === 'ProposalQuoteDetail') {
        return {
          ...state,
          updating: false,
          fetchingTemp: false,
          proposalForm: payload?.value,
        };
      }

      return {
        ...state,
        updating: false,
        fetchingTemp: false,
        proposalForm: state.proposalForm,
      };
    }

    case constants.UPDATE_ACTIVE_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      let updatedProposalForm;
      if (payload.keyName === 'ProposalQuoteDetail') {
        updatedProposalForm = {
          ...copyProposalForm,
          ProposalQuoteDetail: copyProposalForm.ProposalQuoteDetail.map((e) => {
            return {
              ...e,
              isActive: payload.value.isActive,
            };
          }),
        };
      } else if (payload.keyName === 'ProposalCustomPage') {
        updatedProposalForm = {
          ...copyProposalForm,
          ProposalCustomPage: copyProposalForm.ProposalCustomPage.map((e) => {
            return {
              ...e,
              isActive:
                e.id === payload.value.pageId
                  ? payload.value.isActive
                  : e.isActive,
            };
          }),
        };
      } else {
        updatedProposalForm = {
          ...copyProposalForm,
          [payload.keyName]: {
            ...copyProposalForm[payload.keyName],
            isActive: payload.value.isActive,
          },
        };
      }

      // const updatedSideNav = payloads.getSideNav(updatedProposalForm);
      return {
        ...state,
        updating: true,
        // sideNav: updatedSideNav,
        proposalForm: updatedProposalForm,
      };
    }

    case constants.UPDATE_PAGE_ORDER_REQUEST: {
      const copySide = [...state.sideNav];
      const copyProposalForm = { ...state.proposalForm };

      const updatedSideNav = payload.reduce((res, ele, idx) => {
        const obj = copySide.find((e) => e.id === ele.id);
        res.push({
          ...obj,
          pageNumber: idx + 1,
        });
        return res;
      }, []);

      const updatedProposalForm = {
        ...copyProposalForm,
        ProposalTitle: {
          ...copyProposalForm.ProposalTitle,
          pageNumber: updatedSideNav.find(
            (e) => e.id === copyProposalForm?.ProposalTitle?.id
          )?.pageNumber,
        },
        ProposalIntroduction: {
          ...copyProposalForm.ProposalIntroduction,
          pageNumber: updatedSideNav.find(
            (e) => e.id === copyProposalForm?.ProposalIntroduction?.id
          )?.pageNumber,
        },
        ProposalQuoteDetail: copyProposalForm?.ProposalQuoteDetail?.map((e) => {
          return {
            ...e,
            pageNumber: updatedSideNav.find((e1) => e1.page === 'quote')
              ?.pageNumber,
          };
        }),
        ProposalTermsCondition: {
          ...copyProposalForm.ProposalTermsCondition,
          pageNumber: updatedSideNav.find(
            (e) => e.id === copyProposalForm?.ProposalTermsCondition?.id
          )?.pageNumber,
        },
        ProposalWarranty: {
          ...copyProposalForm.ProposalWarranty,
          pageNumber: updatedSideNav.find(
            (e) => e.id === copyProposalForm?.ProposalWarranty?.id
          )?.pageNumber,
        },
        ProposalCustomPage: copyProposalForm?.ProposalCustomPage?.map((e) => {
          return {
            ...e,
            pageNumber: updatedSideNav.find((e1) => e1.id === e?.id)
              ?.pageNumber,
          };
        }),
      };

      return {
        ...state,
        updating: true,
        sideNav: updatedSideNav,
        proposalForm: updatedProposalForm,
      };
    }

    case constants.UPDATE_PROPOSAL_SUCCESS:
    case constants.UPDATE_ACTIVE_SUCCESS:
    case constants.UPDATE_PAGE_ORDER_SUCCESS:
    case constants.UPDATE_PROPOSAL_FAIL:
    case constants.UPDATE_ACTIVE_FAIL:
    case constants.UPDATE_PAGE_ORDER_FAIL:
      return {
        ...state,
        updating: false,
        fetchingTemp: false,
      };

    case constants.UPDATE_SIDENAV_REQUEST: {
      const copySide = [...state.sideNav];
      const updatedSideNav = copySide.map((e) => {
        return {
          ...e,
          name:
            e?.id === payload?.value?.id ? payload?.value?.pageTitle : e?.name,
        };
      });
      return {
        ...state,
        sideNav: updatedSideNav,
      };
    }

    case constants.CREATE_PAGE_SUCCESS: {
      const copySide = [...state.sideNav];
      const copyProposalForm = { ...state.proposalForm };
      const data = [...state.proposalForm.ProposalCustomPage, payload];
      copyProposalForm['ProposalCustomPage'] = data;
      copySide.push({
        id: payload?.id,
        name: payload?.pageTitle,
        value: true,
        page: payload?.page,
      });
      return {
        ...state,
        creating: false,
        sideNav: copySide,
        proposalForm: copyProposalForm,
      };
    }

    case constants.DELETE_PROPOSAL_REQUEST:
      return {
        ...state,
        deleting: payload,
      };

    case constants.DELETE_PROPOSAL_SUCCESS: {
      const copyRows = [...state.proposals.rows];
      const updatedRows = copyRows.filter((e) => e.id !== state.deleting);
      return {
        ...state,
        proposals: {
          ...state.proposals,
          rows: updatedRows,
        },
        deleted: true,
      };
    }

    case constants.DELETE_PROPOSAL_FAIL:
      return {
        ...state,
        deleting: false,
      };

    case constants.RESET_DELETE_PROPOSAL:
      return {
        ...state,
        deleted: false,
        deleting: false,
      };

    case constants.UPDATE_SETTINGS_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      const updateProposalForm = {
        ...copyProposalForm,
        ContactProposal: {
          ...copyProposalForm.ContactProposal,
          id: payload?.contact?.id,
          name: payload?.contact?.name,
        },
        name: payload?.name,
        colorCode: payload?.colorCode,
      };
      return {
        ...state,
        updateSettings: true,
        proposalForm: updateProposalForm,
      };
    }

    case constants.UPDATE_SETTINGS_SUCCESS:
    case constants.UPDATE_SETTINGS_FAIL:
      return {
        ...state,
        updateSettings: false,
        refetch: true,
      };

    case constants.TITLE_PROGRESS:
      return {
        ...state,
        titleProgress: payload,
      };

    case constants.RESET_TITLE_PROGRESS:
      return {
        ...state,
        titleProgress: 0,
        titleUploading: false,
        titleUploaded: false,
      };

    case constants.TITLE_LOGO_PROGRESS:
      return {
        ...state,
        logoProgress: payload,
      };

    case constants.RESET_TITLE_LOGO_PROGRESS:
      return {
        ...state,
        logoProgress: 0,
        logoUploading: false,
        logoUploaded: false,
      };

    case constants.UPLOAD_TITLE_IMAGE_REQUEST:
      return {
        ...state,
        titleUploading: true,
      };

    case constants.UPLOAD_TITLE_IMAGE_SUCCESS:
      return {
        ...state,
        titleUploading: false,
        titleUploaded: payload?.fileName,
      };

    case constants.UPLOAD_PROPOSAL_TITLE_IMAGE_FAIL:
      return {
        ...state,
        titleUploading: false,
      };

    case constants.UPLOAD_TITLE_LOGO_REQUEST:
      return {
        ...state,
        logoUploading: true,
      };

    case constants.UPLOAD_TITLE_LOGO_SUCCESS:
      return {
        ...state,
        logoUploading: false,
        logoUploaded: payload?.fileName,
      };

    case constants.UPLOAD_PROPOSAL_TITLE_LOGO_FAIL:
      return {
        ...state,
        logoUploading: false,
      };

    case constants.SAVE_PAGE_TEMPLATE_REQUEST: {
      let updatedProposalForm, updatedTempProposal;
      const copyProposalForm = { ...state.proposalForm };
      const copy = { ...state.listTemp };
      // const newArr = [...copy?.rows, payload?.value];

      const keyName = payload.keyName;

      if (keyName === undefined) {
        updatedProposalForm = {
          ...copyProposalForm,
          isTemplate: true,
          templateName: payload?.value?.templateName,
          ProposalTitle: {
            ...copyProposalForm?.ProposalTitle,
            isTemplate: true,
          },
          ProposalIntroduction: {
            ...copyProposalForm?.ProposalIntroduction,
            isTemplate: true,
          },
          ProposalQuoteDetail: copyProposalForm?.ProposalQuoteDetail?.map(
            (item) => {
              return {
                ...item,
                isTemplate: true,
              };
            }
          ),
          ProposalTermsCondition: {
            ...copyProposalForm?.ProposalTermsCondition,
            isTemplate: true,
          },
          ProposalWarranty: {
            ...copyProposalForm?.ProposalWarranty,
            isTemplate: true,
          },
        };
        return {
          ...state,
          savingProposal: true,
          fetchingTemp: true,
          proposalForm: updatedProposalForm,
        };
      }

      if (keyName === 'ProposalQuoteDetail') {
        updatedProposalForm = {
          ...copyProposalForm,
          ProposalQuoteDetail: copyProposalForm?.ProposalQuoteDetail?.map(
            (item) => {
              if (item?.id === payload?.value?.id) {
                return {
                  ...item,
                  isTemplate: true,
                };
              }
              return item;
            }
          ),
        };
        return {
          ...state,
          savingProposal: true,
          fetchingTemp: true,
          proposalForm: updatedProposalForm,
        };
      } else {
        updatedTempProposal = {
          ...copyProposalForm,
          [keyName]: {
            ...copyProposalForm[keyName],
            isTemplate: true,
            templateName: payload?.value?.templateName,
          },
        };
        return {
          ...state,
          savingPage: true,
          fetchingTemp: true,
          proposalForm: updatedTempProposal,
        };
      }
    }

    case constants.SAVE_PAGE_TEMPLATE_SUCCESS:
    case constants.SAVE_PAGE_TEMPLATE_FAIL:
      return {
        ...state,
        savingPage: false,
      };

    case constants.SAVE_PROPOSAL_TEMPLATE_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      const updatedProposalForm = {
        ...copyProposalForm,
        isTemplate: true,
      };
      return {
        ...state,
        savingProposal: true,
        proposalForm: updatedProposalForm,
      };
    }

    case constants.SAVE_PROPOSAL_TEMPLATE_SUCCESS:
    case constants.SAVE_PROPOSAL_TEMPLATE_FAIL:
      return {
        ...state,
        savingProposal: false,
      };

    case constants.SEND_PROPOSAL_REQUEST:
      return {
        ...state,
        proposalSent: true,
        sending: true,
      };

    case constants.SEND_PROPOSAL_SUCCESS:
      return {
        ...state,
        proposalSent: false,
        sending: false,
      };
    case constants.SEND_PROPOSAL_FAIL:
      return {
        ...state,
        proposalSent: false,
        sending: false,
      };

    case constants.GENERATE_PDF_REQUEST:
      return {
        ...state,
        generating: true,
        pdf: false,
      };

    case constants.GENERATE_PDF_SUCCESS:
      return {
        ...state,
        generating: false,
        pdf: payload,
      };

    case constants.GENERATE_PDF_FAIL:
      return {
        ...state,
        generating: false,
      };

    case constants.DELETE_PAGE_REQUEST: {
      const copySideNav = [...state.sideNav];
      const copyProposalForm = { ...state.proposalForm };
      const updatedSideNav = copySideNav.filter((e) => e?.id !== payload);
      const updatedProposalForm = {
        ...copyProposalForm,
        ProposalCustomPage: copyProposalForm?.ProposalCustomPage.filter(
          (e) => e?.id !== payload
        ),
      };
      return {
        ...state,
        deletingPage: true,
        sideNav: updatedSideNav,
        proposalForm: updatedProposalForm,
      };
    }

    case constants.DELETE_PAGE_SUCCESS:
    case constants.DELETE_PAGE_FAIL:
      return {
        ...state,
        deletingPage: false,
      };

    case constants.GET_QUOTE_DETAIL_SETTINGS: {
      return {
        ...state,
        getPage: {
          ...state.getPage,
          ...payload.data,
        },
      };
    }

    //----------------Accept Proposal------------------------

    case constants.UPDATE_ACCEPT_PROPOSAL_REQUEST:
      return {
        ...state,
        acceptLoading: true,
        refetchProposal: false,
      };

    case constants.UPDATE_ACCEPT_PROPOSAL_SUCCESS:
      return {
        ...state,
        acceptLoading: false,
        refetchProposal: true,
        proposal: payload,
      };

    case constants.UPDATE_ACCEPT_PROPOSAL_FAIL:
      return {
        ...state,
        acceptLoading: false,
        refetchProposal: false,
      };

    //----------------Reject Proposal------------------------

    case constants.UPDATE_REJECT_PROPOSAL_REQUEST:
      return {
        ...state,
        rejectLoading: true,
        refetchProposal: false,
      };

    case constants.UPDATE_REJECT_PROPOSAL_SUCCESS:
      return {
        ...state,
        rejectLoading: false,
        refetchProposal: true,
        proposal: payload,
      };

    case constants.UPDATE_REJECT_PROPOSAL_FAIL:
      return {
        ...state,
        rejectLoading: false,
        refetchProposal: false,
      };

    //-----------------------Proposal-Template---------------------

    case constants.GET_PROPOSAL_TEMPLATE_REQUEST:
      return {
        ...state,
        loadingTemp: true,
        refetch: false,
      };

    case constants.GET_PROPOSAL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loadingTemp: false,
        refetch: false,
        listTemp: payload,
      };

    case constants.GET_PROPOSAL_TEMPLATE_FAIL:
      return {
        ...state,
        loadingTemp: false,
      };

    //----------------- UPDATE FOR QUOTE DETAIL --------------------
    case constants.UPDATE_PROPOSAL_FOR_QUOTE_DETAIL_ARRAY: {
      return {
        ...state,
        proposalForm: {
          ...state.proposalForm,
          [payload.titleKey]: payload.data,
        },
      };
    }

    case constants.QUOTE_DETAIL_GET_PAGE_SETTINGS_REQUEST:
      return {
        ...state,
        loadPage: true,
      };

    case constants.QUOTE_DETAIL_GET_PAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        loadPage: false,
        getPage: payload,
      };

    case constants.QUOTE_DETAIL_GET_PAGE_SETTINGS_FAIL:
      return {
        ...state,
        loadPage: false,
      };

    case constants.QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_REQUEST:
      return {
        ...state,
        updateSettings: true,
      };

    case constants.QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_SUCCESS:
      return {
        ...state,
        updateSettings: false,
        refetch: true,
      };

    case constants.QUOTE_DETAIL_UPDATE_PAGE_SETTINGS_FAIL:
      return {
        ...state,
        updateSettings: false,
      };

    case constants.QUOTE_DETAIL_CREATE_TABS_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      const data = [...state.proposalForm.ProposalQuoteDetail, payload];
      copyProposalForm['ProposalQuoteDetail'] = data;
      return {
        ...state,
        QuotePageload: true,
        proposalForm: copyProposalForm,
      };
    }

    case constants.QUOTE_DETAIL_CREATE_TABS_SUCCESS: {
      const copyProposalForm = { ...state.proposalForm };
      const copyProposalFormData = copyProposalForm.ProposalQuoteDetail.map(
        (ele) => {
          return {
            ...ele,
            id: ele.id === '' ? payload.id : ele.id,
            ProposalQuoteDetailSection: ele.ProposalQuoteDetailSection.map(
              (ProposalQuoteDetailSectionObj) => {
                return {
                  ...ProposalQuoteDetailSectionObj,
                  id:
                    ProposalQuoteDetailSectionObj.id === ''
                      ? payload.ProposalQuoteDetailSection[0].id
                      : ProposalQuoteDetailSectionObj.id,
                  QuoteDetailItems:
                    ProposalQuoteDetailSectionObj.QuoteDetailItems.map(
                      (QuoteDetailItemsObj) => {
                        return {
                          ...QuoteDetailItemsObj,
                          id:
                            QuoteDetailItemsObj.id === ''
                              ? payload.ProposalQuoteDetailSection[0]
                                  .QuoteDetailItems[0].id
                              : QuoteDetailItemsObj.id,
                          sectionId:
                            QuoteDetailItemsObj.sectionId === ''
                              ? payload.ProposalQuoteDetailSection[0]
                                  .QuoteDetailItems[0].sectionId
                              : QuoteDetailItemsObj.sectionId,
                        };
                      }
                    ),
                };
              }
            ),
          };
        }
      );
      copyProposalForm.ProposalQuoteDetail = copyProposalFormData;
      return {
        ...state,
        QuotePageload: false,
        proposalForm: copyProposalForm,
      };
    }

    case constants.QUOTE_DETAIL_CREATE_TABS_FAIL:
      return {
        ...state,
        QuotePageload: false,
      };

    case constants.QUOTE_DETAIL_TABS_DELETE_REQUEST: {
      const copyProposalForm = { ...state.proposalForm };
      copyProposalForm['ProposalQuoteDetail'] =
        copyProposalForm.ProposalQuoteDetail.filter(
          (ele) => ele.id !== payload.id
        );
      return {
        ...state,
        QuotePageload: true,
        proposalForm: copyProposalForm,
      };
    }

    case constants.QUOTE_DETAIL_TABS_DELETE_SUCCESS:
    case constants.QUOTE_DETAIL_TABS_DELETE_FAIL:
      return {
        ...state,
        QuotePageload: false,
      };

    case constants.QUOTE_DETAIL_CREATE_SECTION_REQUEST:
      return {
        ...state,
        creatingSection: true,
      };

    case constants.QUOTE_DETAIL_CREATE_SECTION_SUCCESS: {
      const copy = { ...state.proposalForm };
      const updatedProposalQuoteDetail = copy.ProposalQuoteDetail.map(
        (item) => {
          if (item.id === payload.proposalQuoteDetailId) {
            return {
              ...item,
              ProposalQuoteDetailSection: [
                ...item.ProposalQuoteDetailSection,
                payload,
              ],
            };
          }
          return { ...item };
        }
      );
      return {
        ...state,
        creatingSection: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalQuoteDetail: updatedProposalQuoteDetail,
        },
      };
    }

    case constants.QUOTE_DETAIL_CREATE_SECTION_FAIL:
      return {
        ...state,
        creatingSection: false,
      };

    case constants.QUOTE_DETAIL_DELETE_SECTION_REQUEST:
      return {
        ...state,
        deleteProposalSection: payload,
      };

    case constants.QUOTE_DETAIL_DELETE_ITEM_SUCCESS:
    case constants.QUOTE_DETAIL_DELETE_SECTION_SUCCESS:
      const copy = { ...state.proposalForm };
      const arr = copy.ProposalQuoteDetail.map((quote) => {
        if (quote.id === payload.id) {
          return {
            ...payload,
          };
        }
        return quote;
      });

      return {
        ...state,
        refetch: true,
        deletingItem: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalQuoteDetail: arr,
        },
      };

    case constants.QUOTE_DETAIL_DELETE_SECTION_FAIL:
      return {
        ...state,
        deleteProposalSection: false,
      };

    case constants.QUOTE_DETAIL_CREATE_ITEM_REQUEST:
      return {
        ...state,
        creatingItem: true,
      };

    case constants.QUOTE_DETAIL_CREATE_ITEM_SUCCESS: {
      const copy = { ...state.proposalForm };
      const newArr = copy.ProposalQuoteDetail.map((quote) => {
        if (quote.id === payload.tabId) {
          return {
            ...quote,
            ProposalQuoteDetailSection: quote.ProposalQuoteDetailSection.map(
              (section) => {
                if (section.id === payload.sectionId) {
                  return {
                    ...section,
                    QuoteDetailItems: [...section.QuoteDetailItems, payload],
                  };
                }
                return section;
              }
            ),
          };
        }
        return quote;
      });
      return {
        ...state,
        creatingItem: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalQuoteDetail: newArr,
        },
      };
    }

    case constants.QUOTE_DETAIL_CREATE_ITEM_FAIL:
      return {
        ...state,
        creatingItem: false,
      };

    case constants.QUOTE_DETAIL_DELETE_ITEM_REQUEST:
      return {
        ...state,
        deletingItem: true,
      };

    case constants.QUOTE_DETAIL_DELETE_ITEM_FAIL:
      return {
        ...state,
        deletingItem: false,
      };

    case constants.RESET_PROPOSALS_REFETCH:
      return {
        ...state,
        refetch: false,
        reloading: false,
        loadingProposal: false,
        getProposal: null,
        proposal: null,
        pdfData: null,
        listTemp: null,
      };

    case constants.GET_NOTES_REQUEST:
      return {
        ...state,
        loadingNotes: true,
      };

    case constants.GET_NOTES_SUCCESS:
      return {
        ...state,
        loadingNotes: false,
        notes: payload,
      };

    case constants.GET_NOTES_FAIL:
      return {
        ...state,
        loadingNotes: false,
      };

    case constants.CREATE_NOTE_REQUEST:
      return {
        ...state,
        creatingNote: true,
      };

    case constants.CREATE_NOTE_SUCCESS: {
      const updatedNotes = [payload, ...state.notes];
      return {
        ...state,
        creatingNote: false,
        notes: updatedNotes,
      };
    }

    case constants.CREATE_NOTE_FAIL:
      return {
        ...state,
        creatingNote: false,
      };

    case constants.UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        attachUploading: true,
        logoUploaded: payload?.fileName,
      };

    case constants.UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachUploading: false,
        attachUploaded: payload?.fileName,
      };

    case constants.UPLOAD_ATTACHMENT_FAIL:
      return {
        ...state,
        attachUploading: false,
      };

    case constants.ATTACHMENT_PROGRESS:
      return {
        ...state,
        attachProgress: payload,
      };

    case constants.RESET_ATTACHMENT_PROGRESS:
      return {
        ...state,
        attachProgress: 0,
        attachUploading: false,
        attachUploaded: false,
      };

    case constants.DELETE_NOTE_REQUEST:
      return {
        ...state,
        deletingNote: payload,
      };

    case constants.DELETE_NOTE_SUCCESS: {
      const updatedNotes = state.notes.filter(
        (e) => e?.id !== state.deletingNote
      );
      return {
        ...state,
        deletingNote: false,
        notes: updatedNotes,
      };
    }

    case constants.DELETE_NOTE_FAIL:
      return {
        ...state,
        deletingNote: true,
      };

    case constants.CREATE_CUSTOM_SECTION_REQUEST:
      return {
        ...state,
        createSection: true,
      };

    case constants.CREATE_CUSTOM_SECTION_SUCCESS:
      const copyState = { ...state.proposalForm };
      const updatedCustomPage = copyState?.ProposalCustomPage.map((item) => {
        if (item.id === payload?.data?.customPageId) {
          return {
            ...item,
            CustomPageSections: [...item.CustomPageSections, payload?.value],
          };
        }
        return { ...item };
      });

      return {
        ...state,
        createSection: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalCustomPage: updatedCustomPage,
        },
      };

    case constants.CREATE_CUSTOM_SECTION_FAIL:
      return {
        ...state,
        createSection: false,
      };

    case constants.CREATE_CUSTOM_SECTION_ITEM_REQUEST:
      return {
        ...state,
        createItem: true,
      };

    case constants.CREATE_CUSTOM_SECTION_ITEM_SUCCESS:
      const copyStateItem = { ...state.proposalForm };
      const newArr = copyStateItem.ProposalCustomPage.map((item) => {
        if (item.id === payload?.pageId) {
          return {
            ...item,
            CustomPageSections: item.CustomPageSections.map((section) => {
              if (section.id === payload?.data?.sectionId) {
                return {
                  ...section,
                  CustomPageSectionItems: [
                    ...section.CustomPageSectionItems,
                    payload?.value,
                  ],
                };
              }
              return section;
            }),
          };
        }
        return item;
      });

      return {
        ...state,
        createItem: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalCustomPage: newArr,
        },
      };

    case constants.CREATE_CUSTOM_SECTION_ITEM_FAIL:
      return {
        ...state,
        createItem: false,
      };

    case constants.CUSTOM_IMAGE_PROGRESS:
      return {
        ...state,
        customImageProgress: payload,
      };

    case constants.RESET_CUSTOM_IMAGE_PROGRESS:
      return {
        ...state,
        imageUploading: false,
        imageUploaded: false,
        customInd: null,
      };

    case constants.UPLOAD_CUSTOM_IMAGE_REQUEST:
      return {
        ...state,
        imageUploading: true,
      };

    case constants.UPLOAD_CUSTOM_IMAGE_SUCCESS:
      const newObj = { ...state.proposalForm };
      const updatedCustomArr = newObj?.ProposalCustomPage?.map((custom) => {
        if (custom?.id === payload?.pageId) {
          return {
            ...custom,
            CustomPageSections: custom?.CustomPageSections?.map(
              (section, sectionInd) => {
                if (sectionInd === payload?.key?.sectionInd) {
                  return {
                    ...section,
                    CustomPageSectionItems:
                      section?.CustomPageSectionItems?.map((item, itemInd) => {
                        if (itemInd === payload?.key?.itemInd) {
                          return {
                            ...item,
                            image: payload?.value?.fileName,
                          };
                        }
                        return item;
                      }),
                  };
                }
                return section;
              }
            ),
          };
        }
        return custom;
      });

      return {
        ...state,
        imageUploading: false,
        customInd: payload?.key,
        imageUploaded: payload?.value?.fileName,
        ProposalCustomPage: updatedCustomArr,
      };

    case constants.UPLOAD_CUSTOM_IMAGE_FAIL:
      return {
        ...state,
        imageUploading: false,
      };

    case constants.DELETE_CUSTOM_PAGE_SECTION_REQUEST:
      return {
        ...state,
        deletingCustomSection: true,
      };

    case constants.DELETE_CUSTOM_PAGE_SECTION_SUCCESS: {
      const stateObj = { ...state.proposalForm };
      const updatedArr = stateObj.ProposalCustomPage.map((section) => {
        const updatedCustomPageSections = section?.CustomPageSections?.filter(
          (pageSection) => pageSection?.id !== payload.sectionId
        );
        return {
          ...section,
          CustomPageSections: updatedCustomPageSections,
        };
      });
      return {
        ...state,
        deletingCustomSection: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalCustomPage: updatedArr,
        },
      };
    }

    case constants.DELETE_CUSTOM_PAGE_SECTION_FAIL:
      return {
        ...state,
        deletingCustomSection: false,
      };

    case constants.DELETE_CUSTOM_PAGE_ITEM_REQUEST:
      return {
        ...state,
        deletingCustomItem: true,
      };

    case constants.DELETE_CUSTOM_PAGE_ITEM_SUCCESS: {
      const { ProposalCustomPage } = state.proposalForm;
      const updatedProposalCustomPage = ProposalCustomPage.map((section) => {
        const updatedCustomPageSections = section.CustomPageSections.map(
          (pageSection) => {
            const updatedCustomPageSectionItems =
              pageSection.CustomPageSectionItems.filter(
                (item) => item.id !== payload.itemId
              );
            return {
              ...pageSection,
              CustomPageSectionItems: updatedCustomPageSectionItems,
            };
          }
        );
        return {
          ...section,
          CustomPageSections: updatedCustomPageSections,
        };
      });

      return {
        ...state,
        deletingCustomItem: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalCustomPage: updatedProposalCustomPage,
        },
      };
    }

    case constants.DELETE_CUSTOM_PAGE_ITEM_FAIL:
      return {
        ...state,
        deletingCustomItem: false,
      };

    case constants.PROPOSAL_INCREASE_COUNT_REQUEST:
      return {
        ...state,
        loadingCount: true,
      };

    case constants.PROPOSAL_INCREASE_COUNT_SUCCESS:
    case constants.PROPOSAL_INCREASE_COUNT_FAIL:
      return {
        ...state,
        loadingCount: false,
      };

    case constants.PROPOSAL_MODIFY_SUCCESS:
      return {
        ...state,
        proposalForm: payload,
      };

    case constants.GET_CLIENT_PROPOSAL_LIST_REQUEST:
      return {
        ...state,
        loadingList: true,
      };

    case constants.GET_CLIENT_PROPOSAL_LIST_SUCCESS:
      return {
        ...state,
        loadingList: false,
        clientArray: payload,
      };

    case constants.GET_CLIENT_PROPOSAL_LIST_FAIL:
      return {
        ...state,
        loadingList: false,
      };

    case constants.UPDATE_PROPOSALS_PAGE:
      return {
        ...state,
        proposals: {
          ...state?.proposals,
          page: payload,
        },
      };

    case constants.DELETE_CUSTOM_PAGE_SECTION_IMG_REQUEST:
      return {
        ...state,
        deletingCustomSectionImg: true,
      };

    case constants.DELETE_CUSTOM_PAGE_SECTION_IMG_SUCCESS: {
      const newObj = { ...state.proposalForm };
      const updatedCustomArr = newObj?.ProposalCustomPage?.map((custom) => {
        if (custom?.id === payload?.customPageId) {
          return {
            ...custom,
            CustomPageSections: custom?.CustomPageSections?.map(
              (section, sectionInd) => {
                if (sectionInd === payload?.sectionInd) {
                  return {
                    ...section,
                    CustomPageSectionItems:
                      section?.CustomPageSectionItems?.map((item, itemInd) => {
                        if (itemInd === payload?.itemInd) {
                          return {
                            ...item,
                            image: '',
                          };
                        }
                        return item;
                      }),
                  };
                }
                return section;
              }
            ),
          };
        }
        return custom;
      });
      return {
        ...state,
        deletingCustomSectionImg: false,
        ProposalCustomPage: updatedCustomArr,
      };
    }

    case constants.DELETE_CUSTOM_PAGE_SECTION_IMG_FAIL:
      return {
        ...state,
        deletingCustomSectionImg: false,
      };

    case constants.QUOTE_DETAIL_CREATE_TAX_RATE_REQUEST:
      return {
        ...state,
        creatingTax: true,
      };

    case constants.QUOTE_DETAIL_CREATE_TAX_RATE_SUCCESS: {
      const copyProposalQuoteDetail = [
        ...state.proposalForm.ProposalQuoteDetail,
      ];
      const TaxValue = payload;
      const newData = copyProposalQuoteDetail.map((e) => ({
        ...e,
        tax: TaxValue.taxRate,
      }));
      return {
        ...state,
        creatingTax: false,
        proposalForm: {
          ...state.proposalForm,
          ProposalQuoteDetail: newData,
        },
      };
    }

    case constants.QUOTE_DETAIL_CREATE_TAX_RATE_FAIL:
      return {
        ...state,
        creatingTax: false,
      };

    case constants.QUOTE_DETAIL_GET_TAX_ALL_REQUEST:
      return {
        ...state,
        gettingAllTax: true,
      };

    case constants.QUOTE_DETAIL_GET_TAX_ALL_SUCCESS:
      return {
        ...state,
        gettingAllTaxValue: payload,
      };

    case constants.QUOTE_DETAIL_GET_TAX_ALL_FAIL:
      return {
        ...state,
        gettingAllTax: false,
      };

    case constants.ACCEPT_PROPOSAL_VIEW_UPDATE_REQUEST:
      return {
        ...state,
        updatingView: true,
        fetchingView: false,
      };

    case constants.ACCEPT_PROPOSAL_VIEW_UPDATE_SUCCESS:
      return {
        ...state,
        updatingView: false,
        fetchingView: true,
      };

    case constants.ACCEPT_PROPOSAL_VIEW_UPDATE_FAIL:
      return {
        ...state,
        updatingView: false,
        fetchingView: false,
      };

    case constants.UPDATE_CUSTOMER_PROPOSAL_REQUEST:
      return {
        ...state,
        updatingCustomer: true,
        fetchingCustomer: false,
      };

    case constants.UPDATE_CUSTOMER_PROPOSAL_SUCCESS:
      return {
        ...state,
        updatingCustomer: false,
        fetchingCustomer: true,
        customerProposal: payload,
      };

    case constants.UPDATE_CUSTOMER_PROPOSAL_FAIL:
      return {
        ...state,
        updatingCustomer: false,
        fetchingCustomer: false,
      };

    case constants.GET_CUSTOMER_PROPOSAL_BY_ID_REQUEST:
      return {
        ...state,
        gettingProposal: true,
        fetchingCustomer: false,
        fetchClient: false,
        refetchProposal: false,
      };

    case constants.GET_CUSTOMER_PROPOSAL_BY_ID_SUCCESS:
      return {
        ...state,
        gettingProposal: false,
        refetchProposal: false,
        fetchingCustomer: false,
        fetchClient: false,
        customerProposal: payload?.proposalForm,
      };

    case constants.GET_CUSTOMER_PROPOSAL_BY_ID_FAIL:
      return {
        ...state,
        gettingProposal: false,
        fetchingCustomer: false,
        fetchClient: false,
        refetchProposal: false,
        notFound: payload.msg,
      };

    case constants.DELETE_CUSTOMER_PROPOSAL_PRICE_REQUEST:
      return {
        ...state,
        deletingCustomer: true,
        fetchClient: false,
      };

    case constants.DELETE_CUSTOMER_PROPOSAL_PRICE_SUCCESS:
      return {
        ...state,
        deletingCustomer: false,
        fetchClient: true,
        customerProposal: payload,
      };

    case constants.DELETE_CUSTOMER_PROPOSAL_PRICE_FAIL:
      return {
        ...state,
        deletingCustomer: false,
        fetchClient: false,
      };

    case constants.GET_CUSTOMER_PORTAL_ALL_PROPOSAL_REQUEST:
      return {
        ...state,
        getting: true,
      };

    case constants.GET_CUSTOMER_PORTAL_ALL_PROPOSAL_SUCCESS:
      return {
        ...state,
        getting: false,
        gettingCustomerAllProposal: payload,
      };

    case constants.GET_CUSTOMER_PORTAL_ALL_PROPOSAL_FAIL:
      return {
        ...state,
        getting: false,
      };

    case constants.GET_CUSTOMER_PROPOSAL_MARK_VIEW_REQUEST:
      return {
        ...state,
        markView: true,
      };

    case constants.GET_CUSTOMER_PROPOSAL_MARK_VIEW_SUCCESS:
    case constants.GET_CUSTOMER_PROPOSAL_MARK_VIEW_FAIL:
      return {
        ...state,
        markView: false,
      };

    case constants.RESET_VALUE:
      return {
        ...state,
        fetchClient: false,
        fetchingCustomer: false,
      };

    default:
      return state;
  }
}
