import * as constants from 'redux/appointments/constants';

const initialState = {
  loadingAppt: false,
  loadingAll: false,
  appts: [],
  apptsList: [],
  creatingAppt: false,
  fetchingAppt: false,
  updating: false,
  appt: null,
  deleting: false,
  loadingReq: false,
  apptRequest: null,
  creatingQuest: false,
  submited: false,
  fecthingAllReqs: false,
  allReqs: [],
  loadingApptReq: false,
  apptReq: null,
  deletingReq: false,
  schedulingAppt: false,
  isLoging: false,
  isCompleting: false,
  fetchingCompleteAppt: false,
  laodingAllAppts: false,
  isGetting: false,
  getApptReqFormData: null,
  refetchAppt: false,
  isRequesting: false,
  apptReqData: null,
  fetchReqData: false,
};

export default function appointmentsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_EVENT_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loadingAppt: true,
        fetchingCompleteAppt: false,
      };

    case constants.GET_EVENT_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loadingAppt: false,
        fetchingCompleteAppt: false,
        appts: payload,
      };

    case constants.GET_EVENT_APPOINTMENTS_FAIL:
      return {
        ...state,
        loadingAppt: false,
        fetchingCompleteAppt: false,
      };

    case constants.GET_ALL_APPOINTMENTS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        laodingAllAppts: false,
        fetchingCompleteAppt: false,
      };

    case constants.GET_ALL_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loadingAll: false,
        fetchingCompleteAppt: false,
        apptsList: payload?.value,
      };

    case constants.GET_ALL_APPOINTMENTS_FAIL:
      return {
        ...state,
        loadingAll: false,
        fetchingCompleteAppt: false,
      };

    case constants.GET_APPOINTMENT_BY_ID_REQUEST:
      return {
        ...state,
        fetchingAppt: true,
        laodingAllAppts: false,
      };

    case constants.GET_APPOINTMENT_BY_ID_SUCCESS:
      return {
        ...state,
        fetchingAppt: false,
        appt: payload?.value,
      };

    case constants.GET_APPOINTMENT_BY_ID_FAIL:
      return {
        ...state,
        fetchingAppt: false,
      };

    case constants.CREATE_NEW_APPOINTMENT_REQUEST:
      return {
        ...state,
        creatingAppt: true,
        laodingAllAppts: false,
      };

    case constants.CREATE_NEW_APPOINTMENT_SUCCESS:
      return {
        ...state,
        creatingAppt: false,
        laodingAllAppts: true,
      };

    case constants.CREATE_NEW_APPOINTMENT_FAIL:
      return {
        ...state,
        creatingAppt: false,
      };

    case constants.UPDATE_APPOINTMENT_REQUEST:
      return {
        ...state,
        updating: true,
      };

    case constants.UPDATE_APPOINTMENT_SUCCESS:
      const prevAppts = { ...state.apptsList };
      let newAppt,
        newDate,
        daysToAdd = 1;
      newDate = new Date(payload?.value?.appointmentDate);
      newDate.setDate(newDate.getDate() + daysToAdd);

      newAppt = {
        ...prevAppts,
        rows: prevAppts?.rows?.map((item) => {
          const {
            id,
            colorCode,
            eventTypeId,
            assignedTo,
            contactId,
            appointmentDate,
            firstName,
            lastName,
            scheduledTime,
            eventDuration,
            eventName,
            contactFirstName,
            contactLastName,
            eventColor,
          } = payload?.value;

          if (item?.id === payload?.value?.id) {
            return {
              ...item,
              id: id,
              colorCode: colorCode,
              eventTypeId: eventTypeId,
              assignedTo: assignedTo,
              contactId: contactId,
              appointmentDate: appointmentDate,
              firstName: firstName,
              lastName: lastName,
              scheduledTime: scheduledTime,
              eventDuration: eventDuration,
              eventName: eventName,
              eventColor: eventColor,
              eventColorCode: colorCode,
              contactFirstName: contactFirstName,
              contactLastName: contactLastName,
              title: eventName,
              backgroundColor: colorCode,
              borderColor: 'white',
              border: 'none',
              textColor: 'white',
              fontWeight: 600,
              editable: true,
              clickable: true,
              overlap: false,
              description: '',
              client: `${contactFirstName || 'No Data'} ${
                contactLastName || 'No Data'
              }`,
              timeDuration: `(${eventDuration} mins)`,
              start: newDate.toISOString(),
            };
          }
          return item;
        }),
      };

      return {
        ...state,
        updating: false,
        apptsList: newAppt,
      };

    case constants.UPDATE_APPOINTMENT_FAIL:
      return {
        ...state,
        updating: false,
      };

    case constants.DELETE_APPOINTMENT_BY_ID_REQUEST:
      return {
        ...state,
        deleting: true,
      };

    case constants.DELETE_APPOINTMENT_BY_ID_SUCCESS:
      const prevData = { ...state.apptsList };
      let updatedData;

      updatedData = {
        ...prevData,
        rows: prevData?.rows?.filter((item) => item?.id !== payload?.data?.id),
      };

      return {
        ...state,
        deleting: false,
        apptsList: updatedData,
      };

    case constants.DELETE_APPOINTMENT_BY_ID_FAIL:
      return {
        ...state,
        deleting: false,
      };

    case constants.GET_APPOINTMENT_REQUEST_FORM_REQUEST:
      return {
        ...state,
        loadingReq: true,
      };

    case constants.GET_APPOINTMENT_REQUEST_FORM_SUCCESS:
      return {
        ...state,
        loadingReq: false,
        submited: false,
        apptRequest: payload,
      };

    case constants.GET_APPOINTMENT_REQUEST_FORM_FAIL:
      return {
        ...state,
        loadingReq: false,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_FORM_REQUEST:
      return {
        ...state,
        creatingQuest: true,
        submited: false,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_FORM_SUCCESS:
      return {
        ...state,
        creatingQuest: false,
        submited: true,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_FORM_FAIL:
      return {
        ...state,
        creatingQuest: false,
        submited: false,
      };

    case constants.GET_ALL_APPOINTMENT_REQUEST_DATA_REQUEST:
      return {
        ...state,
        fecthingAllReqs: true,
      };

    case constants.GET_ALL_APPOINTMENT_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        fecthingAllReqs: false,
        allReqs: payload,
      };

    case constants.GET_ALL_APPOINTMENT_REQUEST_DATA_FAIL:
      return {
        ...state,
        fecthingAllReqs: false,
      };

    case constants.GET_APPOINTMENT_REQUEST_BY_ID_REQUEST:
      return {
        ...state,
        loadingApptReq: true,
      };

    case constants.GET_APPOINTMENT_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        loadingApptReq: false,
        apptReq: payload,
      };

    case constants.GET_APPOINTMENT_REQUEST_BY_ID_FAIL:
      return {
        ...state,
        loadingApptReq: false,
      };

    case constants.DELETE_APPOINTMENT_REQUEST_BY_ID_REQUEST:
      return {
        ...state,
        deletingReq: true,
      };

    case constants.DELETE_APPOINTMENT_REQUEST_BY_ID_SUCCESS:
      const prevReqData = { ...state.allReqs };
      let updatedReqData;

      updatedReqData = {
        ...prevReqData,
        rows: prevReqData?.rows?.filter(
          (item) => item?.id !== payload?.data?.id
        ),
      };

      return {
        ...state,
        deletingReq: false,
        allReqs: updatedReqData,
      };

    case constants.DELETE_APPOINTMENT_REQUEST_BY_ID_FAIL:
      return {
        ...state,
        deletingReq: false,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_SCHEDULE_REQUEST:
      return {
        ...state,
        schedulingAppt: true,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_SCHEDULE_SUCCESS:
      const copyprevAppts = { ...state?.apptsList };
      const copyReqData = { ...state.allReqs };
      let updatednewAppt,
        newReqData,
        newdaysToAddOrSubtract = 1,
        originalnewDate;
      originalnewDate = new Date(payload?.resData?.appointmentDate);
      originalnewDate.setDate(
        originalnewDate.getDate() + newdaysToAddOrSubtract
      );

      newReqData = {
        ...copyReqData,
        rows: copyReqData?.rows?.filter(
          (item) => item?.id !== payload?.apptData?.requestId
        ),
      };

      updatednewAppt = {
        ...copyprevAppts,
        rows: [
          ...copyprevAppts?.rows,
          {
            ...payload?.resData,
            id: payload?.resData?.id,
            colorCode: payload?.resData?.colorCode,
            eventTypeId: payload?.resData?.eventTypeId || 'No Data',
            assignedTo: payload?.resData?.assignedTo,
            contactId: payload?.resData?.contactId,
            appointmentDate: payload?.resData?.appointmentDate,
            firstName: payload?.resData?.firstName,
            lastName: payload?.resData?.lastName,
            scheduledTime: payload?.resData?.scheduledTime,
            eventDuration: payload?.resData?.eventDuration,
            eventName: payload?.resData?.eventName,
            eventColor: payload?.resData?.eventColor,
            eventColorCode: payload?.resData?.colorCode,
            contactFirstName: payload?.resData?.contactFirstName,
            contactLastName: payload?.resData?.contactLastName,
            title: payload?.resData?.eventName,
            backgroundColor: payload?.resData?.colorCode,
            borderColor: 'white',
            border: 'none',
            textColor: 'white',
            fontWeight: 600,
            editable: true,
            clickable: true,
            overlap: false,
            description: '',
            client: `${payload?.resData?.contactFirstName || 'No Data'} ${
              payload?.resData?.contactLastName || 'No Data'
            }`,
            timeDuration: `(${payload?.resData?.eventDuration} mins)`,
            start: originalnewDate.toISOString(),
          },
        ],
      };

      return {
        ...state,
        schedulingAppt: false,
        apptsList: updatednewAppt,
        allReqs: newReqData,
      };

    case constants.CREATE_APPOINTMENT_REQUEST_SCHEDULE_FAIL:
      return {
        ...state,
        schedulingAppt: false,
      };

    case constants.UPDATE_GOOGLE_LOGIN_APPT_REQUEST:
      return {
        ...state,
        isLoging: true,
      };

    case constants.UPDATE_GOOGLE_LOGIN_APPT_SUCCESS:
      const copyAppt = { ...state?.apptsList };
      let newAppmt;
      newAppmt = {
        ...copyAppt,
        syncCalendar: payload?.syncCalendar,
      };

      return {
        ...state,
        isLoging: false,
        apptsList: newAppmt,
      };

    case constants.UPDATE_GOOGLE_LOGIN_APPT_FAIL:
      return {
        ...state,
        isLoging: false,
      };

    case constants.COMPLETE_APPT_SCHEDULE_BY_ID_REQUEST:
      return {
        ...state,
        isCompleting: true,
        fetchingCompleteAppt: false,
      };

    case constants.COMPLETE_APPT_SCHEDULE_BY_ID_SUCCESS:
      return {
        ...state,
        isCompleting: false,
        fetchingCompleteAppt: true,
      };

    case constants.COMPLETE_APPT_SCHEDULE_BY_ID_FAIL:
      return {
        ...state,
        isCompleting: false,
        fetchingCompleteAppt: false,
      };

    case constants.GET_APPT_REQ_FORM_REQUEST:
      return {
        ...state,
        isGetting: true,
      };

    case constants.GET_APPT_REQ_FORM_SUCCESS:
      return {
        ...state,
        isGetting: false,
        refetchAppt: true,
        getApptReqFormData: payload,
      };

    case constants.GET_APPT_REQ_FORM_FAIL:
      return {
        ...state,
        isGetting: false,
      };

    case constants.GET_APPT_REQ_DATA_BY_REQID_REQUEST:
      return {
        ...state,
        isRequesting: true,
        fetchReqData: false,
      };

    case constants.GET_APPT_REQ_DATA_BY_REQID_SUCCESS:
      return {
        ...state,
        isRequesting: false,
        fetchReqData: true,
        apptReqData: payload,
      };

    case constants.GET_APPT_REQ_DATA_BY_REQID_FAIL:
      return {
        ...state,
        isRequesting: false,
        fetchReqData: false,
      };

    case constants.RESET_REQ_APPT_REQUEST:
      return {
        ...state,
        refetchAppt: false,
        isGetting: false,
        getApptReqFormData: null,
      };

    default:
      return state;
  }
}
