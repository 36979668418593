import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography } from '@mui/material';
import TooltipIcon from 'assets/svg/tooltip-icon.svg';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ThemeContext from 'themes/ThemeContext';

const TooltipComponent = ({ title, children, placement, arrow }) => {
  const usertheme = useContext(ThemeContext);

  const ToBeStyledTooltip = ({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ tooltip: className, popper: className }}
      PopperProps={{
        modifiers: [
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: '[data-popper-arrow]',
            },
          },
        ],
      }}
    />
  );

  const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      // maxWidth: 500,
      width: '100%',
      padding: '10px',
      margin: '5px',
      borderRadius: 10,
      border: `1px solid ${usertheme?.lightTheme?.palette?.darkBlue?.main}`,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFFFFF',
      fontSize: 20,
      '&:before': {
        content: '""',
        backgroundColor: '#FFFFFF',
        border: `1px solid ${usertheme?.lightTheme?.palette?.darkBlue?.main}`,
        transform: 'rotate(45deg)',
      },
    },
  }));

  const toolTipTitle = (
    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
      <img src={TooltipIcon} alt="tooltip icon" />
      <Typography
        color={usertheme?.lightTheme?.palette?.gray?.main}
        fontSize={14}
        fontWeight={500}
        textTransform={'capitalize'}
        ml={'4px'}
      >
        {title}
      </Typography>
    </Box>
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12}>
          <StyledTooltip
            title={toolTipTitle}
            placement={placement}
            arrow={arrow}
          >
            <span>{children}</span>
          </StyledTooltip>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TooltipComponent;
