import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid } from '@mui/material';
import Button from 'components/Button';
import ConfirmImg from 'assets/svg/confirm-popup.svg';

const ConfirmModalForm = ({ handleClose, ...props }) => {
  const { t } = useTranslation();

  return (
    <Box className="res_confrim_logout">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box width={'100%'} display={'flex'} justifyContent={'center'}>
            <Box
              sx={{
                width: { xs: '250px', sm: '400px' },
              }}
            >
              <img src={ConfirmImg} alt="ConfirmImg" width={'100%'} />
            </Box>
          </Box>
          <Box mb={2} p={2} borderRadius={2}>
            <Typography
              textAlign={'center'}
              fontSize={
                props?.fullScreen
                  ? { xs: 13, sm: 14, md: 16 }
                  : { xs: 14, sm: 16, md: 18 }
              }
              fontWeight={500}
            >
              {props?.confirmMsg}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box mb={2} display={'flex'} justifyContent={'center'}>
            <Button
              variant="outlined"
              type="button"
              onClick={handleClose}
              sx={{
                marginRight: 2,
                minWidth: props?.fullScreen
                  ? { xs: 50, sm: 75, md: 100 }
                  : { xs: 150, sm: 175, md: 200 },
                fontSize: { xs: '13px', sm: '16px' },
              }}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              type="button"
              onClick={props?.handleSubmit}
              loading={props?.loading}
              loadingText={'Saving...'}
              disabled={props?.loading}
              sx={{
                minWidth: props?.fullScreen
                  ? { xs: 50, sm: 75, md: 100 }
                  : { xs: 150, sm: 175, md: 200 },

                backgroundColor: props?.theme?.palette?.darkBlue?.main,
                fontSize: { xs: '13px', sm: '16px' },
              }}
            >
              {props?.btnMsg}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmModalForm;
