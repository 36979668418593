import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Avatar from 'components/Avatar';
import useResponsiveBreakpoints from 'utils';
import EllipsisTooltip from 'components/ToolTipCom/ToolTipCom';

const getUserName = (initials) => {
  if (!initials) return '-';
  
  const { firstName = '', lastName = '' } = initials;
  const fullName = [firstName, lastName].filter(Boolean).join(' ');
  
  return fullName.trim() || '-';
};

const AccountAvatar = ({ name, ...props }) => {
  const { isXS, isSM, isMD, isLG, isXL } = useResponsiveBreakpoints();
  const userName = getUserName(props?.initials);
  return (
    <Box display="flex" alignItems="center">
      <Avatar name={name} {...props} />
      <Box
        ml={2}
        display="flex"
        alignItems="center"
        className={isLG ? 'userName' : 'userName hw-expandIcon'}
        mr={isLG ? 2 : 0}
      >
        {isLG && (
          <EllipsisTooltip
          fontWeight={600}
          color={'#000'}
          width={'180px'}
          text= {userName}
          >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 'bold',
              color: '#000',
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
          </EllipsisTooltip>
        )}
        <ExpandMoreIcon sx={{ ml: 1, color: '#000' }} />
      </Box>
    </Box>
  );
};

export default AccountAvatar;
