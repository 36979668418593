export const GET_PROPOSAL_BY_PROPOSAL_ID_REQUEST =
  'GET_PROPOSAL_BY_PROPOSAL_ID_REQUEST';
export const GET_PROPOSAL_BY_PROPOSAL_ID_SUCCESS =
  'GET_PROPOSAL_BY_PROPOSAL_ID_SUCCESS';
export const GET_PROPOSAL_BY_PROPOSAL_ID_FAIL =
  'GET_PROPOSAL_BY_PROPOSAL_ID_FAIL';

export const PROPOSAL_ACCEPT_OR_DECLINE_REQUEST =
  'PROPOSAL_ACCEPT_OR_DECLINE_REQUEST';
export const PROPOSAL_ACCEPT_OR_DECLINE_SUCCESS =
  'PROPOSAL_ACCEPT_OR_DECLINE_SUCCESS';
export const PROPOSAL_ACCEPT_OR_DECLINE_FAIL =
  'PROPOSAL_ACCEPT_OR_DECLINE_FAIL';

export const GET_SELECTED_PROPOSALS_REQUEST = 'GET_SELECTED_PROPOSALS_REQUEST';
export const GET_SELECTED_PROPOSALS_SUCCESS = 'GET_SELECTED_PROPOSALS_SUCCESS';
export const GET_SELECTED_PROPOSALS_FAIL = 'GET_SELECTED_PROPOSALS_FAIL';

export const GET_THREAD_ID_REQUEST = 'GET_THREAD_ID_REQUEST';
export const GET_THREAD_ID_SUCCESS = 'GET_THREAD_ID_SUCCESS';
export const GET_THREAD_ID_FAIL = 'GET_THREAD_ID_FAIL';

export const QUERY_PROPOSAL_BY_AI_REQUEST = 'QUERY_PROPOSAL_BY_AI_REQUEST';
export const QUERY_PROPOSAL_BY_AI_SUCCESS = 'QUERY_PROPOSAL_BY_AI_SUCCESS';
export const QUERY_PROPOSAL_BY_AI_FAIL = 'QUERY_PROPOSAL_BY_AI_FAIL';

export const AI_CHECK_STATUS_REQUEST = 'AI_CHECK_STATUS_REQUEST';
export const AI_CHECK_STATUS_SUCCESS = 'AI_CHECK_STATUS_SUCCESS';
export const AI_CHECK_STATUS_FAIL = 'AI_CHECK_STATUS_FAIL';

export const RESET_REFETCH = 'RESET_REFETCH';
export const RESET_AI_STATUS = 'RESET_AI_STATUS';
