import moment from 'moment';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import Papa from 'papaparse';
import {
  getDay,
  addMonths,
  parseISO,
  format,
  addDays,
  isBefore,
  isEqual,
} from 'date-fns';
import i18n from 'i18n';

export const getName = (user) => {
  if (user) {
    const { firstName, lastName } = user;
    if (firstName && lastName)
      return (
        <Typography textTransform={'capitalize'} fontWeight={700}>
          {firstName} {lastName}
        </Typography>
      );
    else
      return (
        <Typography textTransform={'capitalize'} fontWeight={700}>
          firstName
        </Typography>
      );
  } else return null;
};

export const getInitials = ({ firstName, lastName }) => {
  if (firstName && lastName) {
    return `${firstName.toUpperCase()[0]}${lastName.toUpperCase()[0]}`;
  } else {
    return firstName.toUpperCase()[0];
  }
};

export const getInitialsFromStr = (str) => {
  if (str) {
    const newStr = str?.split(' ');
    return `${newStr?.[0]?.[0].toUpperCase()}${newStr?.[
      newStr.length - 1
    ]?.[0]?.toUpperCase()}`;
  } else {
    return '-';
  }
};

export const checkForObjectNA = (obj) => {
  return !Object.values(obj).some(
    (value) => value !== '-' && !(value === 1 && obj.id === 1)
  );
};

export const a = (products, productName) => {
  const matchingOption = products?.rows?.find((option) => {
    return option?.name?.toLowerCase() === productName?.toLowerCase();
  });
  return matchingOption || null;
};

export const getQuoteDetailPage = (arr, index) => {
  const result = arr?.find((item, ind) => {
    return ind === index;
  });
  return result || null;
};

export const getCustomPage = (pages, pageId) => {
  return (
    pages &&
    pages.find((item) => {
      return item?.id === pageId;
    })
  );
};

export const sortArray = (array) => {
  let filterArr =
    array &&
    array?.length >= 0 &&
    [...array]?.sort((a, b) => {
      return a?.pageNumber - b?.pageNumber;
    });

  const findTitles = filterArr?.map((item) => {
    return {
      name: item?.pageTitle,
      page_id: item?.id,
      pageNumber: item?.pageNumber,
      type: 'custom',
      isActive: item?.isActive ? item?.isActive : true,
    };
  });

  return findTitles || null;
};

export const dateFormat = (value) => {
  let finalDate = value ? moment(value).format('MM-DD-YY') : null;
  return finalDate || null;
};

export const longDateFormat = (value) => {
  let finalDate = value ? moment(value).format('LL') : null;
  return finalDate || null;
};

export const fromNow = (date) => {
  return moment(date).fromNow();
};

export const projectDateFormat = (date) => {
  const formattedDate = date
    ? moment(date).locale(i18n.language).format('MMMM Do, YYYY')
    : null;
  return formattedDate || null;
};

export const getCustomPageData = (customArray, pageId) => {
  if (customArray && customArray.length > 0) {
    return customArray.filter((item) => {
      return item.id === window.location.href.split('/').pop();
    });
  }
};

export const getQuoteDetailData = (pageId, ProposalQuoteDetailMap) => {
  return ProposalQuoteDetailMap?.find((quote) => quote?.id === pageId);
};

export const combineAddress = (obj) => {
  if (!obj?.address && !obj?.city) {
    return `${obj?.state} ${obj?.zip}`;
  } else {
    return `${obj?.address} ${obj?.city}, ${obj?.state} ${obj?.zip}`;
  }
  // const newObj = {
  //   address: obj?.address,
  //   city: obj?.city,
  //   state: obj?.state,
  //   zip: obj?.zip,
  // };
  // const values = [];
  // for (const key in newObj) {
  //   if (obj?.hasOwnProperty(key) && obj[key]) {
  //     values.push(obj[key]);
  //   } else {
  //     return '-';
  //   }
  // }
  // return values.join(', ');
};

export const sortArrayWithOrderNo = (arr) => {
  return arr?.sort((a, b) => a[1]?.orderNo - b[1]?.orderNo);
};

export const filterMultipleAddresss = (
  input1,
  input2,
  formik,
  autocompleteRef1,
  autocompleteRef2,
  sameAs,
  setSlcPlace
) => {
  const options = {
    fields: ['formatted_address', 'geometry', 'name', 'address_components'],
    types: [],
  };

  if (input1) {
    const ref1 = new window.google.maps.places.Autocomplete(input1, options);
    autocompleteRef1.current = ref1;

    ref1.addListener('place_changed', () => {
      const place = ref1.getPlace();

      if (!place?.geometry || !place?.geometry?.location) {
        window.alert(`No details available for input: '${place.name}'`);
        return;
      }

      const city = place.address_components.find((item) =>
        item.types.includes('locality') ? item : null
      );

      const state = place.address_components.find((item) =>
        item.types.includes('administrative_area_level_1') ? item : null
      );

      const zipCode = place.address_components.find((item) =>
        item.types.includes('postal_code') ? item : null
      );

      formik.setFieldValue('address1', place?.name);
      formik.setFieldValue('state1', state?.long_name);
      formik.setFieldValue('city1', city?.long_name);
      formik.setFieldValue('zip1', zipCode?.long_name);
      if (
        formik?.values?.hasOwnProperty('lat1') ||
        formik?.values?.hasOwnProperty('long1')
      ) {
        formik.setFieldValue('lat1', place.geometry.location.lat());
        formik.setFieldValue('long1', place.geometry.location.lng());
      }

      if (sameAs) {
        formik.setFieldValue('address2', place?.name);
        formik.setFieldValue('state2', state?.long_name);
        formik.setFieldValue('city2', city?.long_name);
        formik.setFieldValue('zip2', zipCode?.long_name);
        formik.setFieldValue('lat2', place.geometry.location.lat());
        formik.setFieldValue('long2', place.geometry.location.lng());
      }
    });
  }

  if (input2) {
    const ref2 = new window.google.maps.places.Autocomplete(input2, options);

    autocompleteRef2.current = ref2;

    ref2.addListener('place_changed', () => {
      const place1 = ref2.getPlace();

      if (!place1?.geometry || !place1?.geometry?.location) {
        window.alert(`No details available for input: '${place1.name}'`);
        return;
      }

      const city1 = place1.address_components.find((item) =>
        item.types.includes('locality') ? item : null
      );

      const state1 = place1.address_components.find((item) =>
        item.types.includes('administrative_area_level_1') ? item : null
      );

      const zipCode1 = place1.address_components.find((item) =>
        item.types.includes('postal_code') ? item : null
      );

      formik.setFieldValue('address2', place1?.name);
      formik.setFieldValue('state2', state1?.long_name);
      formik.setFieldValue('city2', city1?.long_name);
      formik.setFieldValue('zip2', zipCode1?.long_name);
      formik.setFieldValue('lat2', place1.geometry.location.lat());
      formik.setFieldValue('long2', place1.geometry.location.lng());
    });
  }
};

export const filterSingleAddress = (input, formik, autocompleteRef, arr) => {
  const options = {
    fields: ['formatted_address', 'geometry', 'name', 'address_components'],
    types: [],
  };

  const singleRef = new window.google.maps.places.Autocomplete(input, options);
  autocompleteRef.current = singleRef;

  singleRef.addListener('place_changed', () => {
    const place = singleRef.getPlace();
    if (!place?.geometry || !place?.geometry?.location) {
      window.alert(`No details available for input: '${place.name}'`);
      return;
    }

    const city = place.address_components.find((item) =>
      item.types.includes('locality') ? item : null
    );

    const state = place.address_components.find((item) =>
      item.types.includes('administrative_area_level_1') ? item : null
    );

    const zip = place.address_components.find((item) =>
      item.types.includes('postal_code') ? item : null
    );

    formik.setFieldValue(arr[0], place?.name);
    formik.setFieldValue(arr[1], state?.long_name);
    formik.setFieldValue(arr[2], city?.long_name);
    formik.setFieldValue(arr[3], zip?.long_name);
    if (arr[4]?.includes('lat') || arr[5]?.includes('long')) {
      formik.setFieldValue(arr[4], place?.geometry?.location.lat());
      formik.setFieldValue(arr[5], place?.geometry?.location.lng());
    }
  });
};

export const getLatLong = (name, address, formik) => {
  var geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ address: address }, function (results, status) {
    if (status == 'OK') {
      if (name === 'address2') {
        formik.setFieldValue('lat2', results[0].geometry.location.lat());
        formik.setFieldValue('long2', results[0].geometry.location.lng());
      } else if (name === 'address1') {
        formik.setFieldValue('lat1', results[0].geometry.location.lat());
        formik.setFieldValue('long1', results[0].geometry.location.lng());
      } else {
        formik.setFieldValue('lat1', results[0].geometry.location.lat());
        formik.setFieldValue('long1', results[0].geometry.location.lng());

        formik.setFieldValue('lat2', results[0].geometry.location.lat());
        formik.setFieldValue('long2', results[0].geometry.location.lng());
      }
    }
  });
};

export const flatObjectToString = (obj) => {
  let s = '';
  Object.keys(obj)?.map((key) => {
    if (obj[key] === null) {
      s += key + ':';
    } else if (obj[key].toLocaleDateString) {
      s += key + ': ' + obj[key].toLocaleDateString() + '\n';
    } else if (obj[key] instanceof Array) {
      s += key + ':\n' + listToFlatString(obj[key]);
    } else if (typeof obj[key] == 'object') {
      s += key + ':\n' + flatObjectToString(obj[key]);
    } else {
      s += key + ':' + obj[key];
    }
    s += '\n';
  });
  return s;
};

export const listToFlatString = (list) => {
  let s = '';
  list.map((item) => {
    Object.keys(item)?.map((key) => {
      s += '';
      if (item[key] instanceof Array) {
        s += key + '\n' + listToFlatString(item[key]);
      } else if (typeof item[key] == 'object' && item[key] !== null) {
        s += key + ': ' + flatObjectToString(item[key]);
      } else {
        s +=
          key +
          ': ' +
          (item[key] === null
            ? ''
            : item[key].toLocaleDateString
            ? item[key].toLocaleDateString
            : item[key].toString());
      }
      s += '\n';
    });
  });
  return s;
};

export const flatten = (object, addToList, prefix) => {
  Object.keys(object)?.map((key) => {
    if (object[key] === null) {
      addToList[prefix + key] = '';
    } else if (object[key] instanceof Array) {
      addToList[prefix + key] = listToFlatString(object[key]);
    } else if (
      typeof object[key] == 'object' &&
      !object[key].toLocaleDateString
    ) {
      flatten(object[key], addToList, prefix + key + '.');
    } else {
      addToList[prefix + key] = object[key];
    }
  });
  return addToList;
};

export const convertJsonToCsv = (data) => {
  const flatJSON = data?.map((record) => flatten(record, {}, ''));
  const csv = Papa.unparse(flatJSON);
  return csv;
};

export const getFormatedDates = () => {
  const date = new Date();
  let firstDay, lastDay;
  let obj = {
    formatFirstDay: '',
    formatLastDay: '',
  };
  firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  obj = {
    formatFirstDay: moment(firstDay).format('MM-DD-YY'),
    formatLastDay: moment(lastDay).format('MM-DD-YY'),
  };
  return obj;
};

export const fetchChar = (fullName) => {
  if (fullName !== undefined) {
    const names = fullName.trim().split(' ');
    if (names.length >= 2) {
      const firstNameInitial = names[0].charAt(0);
      const lastNameInitial = names[names.length - 1].charAt(0);
      return `${firstNameInitial?.toUpperCase()} ${lastNameInitial?.toUpperCase()}`;
    }
    return 'No Data';
  }
};

export const extractFirstLetters = (inputString) => {
  let result = {};

  let words = inputString?.split(' ');
  if (words?.length >= 2) {
    let firstLetterFirstWord = words[0][0] || '';
    let firstLetterSecondWord = words[1][0] || '';

    result = {
      firstLetterFirstWord,
      firstLetterSecondWord,
    };
  } else {
    result = '-';
  }

  if (result === '-') {
    return '-';
  } else {
    return `${result?.firstLetterFirstWord?.toUpperCase()} ${result?.firstLetterSecondWord?.toUpperCase()}`;
  }
};

export const filterType = (type) => {
  switch (type) {
    case 'short_answer':
      return 'Short Answer';

    case 'checkbox':
      return 'Checkbox';

    case 'dropdown':
      return 'Dropdown';
    default:
      return;
  }
};

export const arrayWithCommaSeparated = (data) => {
  if (data?.length > 0) {
    return data?.map((e) => e?.answer).join(', ');
  } else {
    return 'No Data';
  }
};

export const calculateYearsMonthsDays = (days) => {
  if (days < 0) {
    return 'Input must be a non-negative number of days.';
  }

  const daysInYear = 365;
  const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let years = 0;
  while (days >= daysInYear) {
    if (isLeapYear(years)) {
      days -= 366;
    } else {
      days -= 365;
    }
    years++;
  }

  let months = 0;
  while (days > 0) {
    if (isLeapYear(years) && months === 1) {
      const daysInLeapYearFebruary = 29;
      if (days >= daysInLeapYearFebruary) {
        days -= daysInLeapYearFebruary;
        months++;
      } else {
        break;
      }
    } else {
      if (days >= daysInMonth[months]) {
        days -= daysInMonth[months];
        months++;
      } else {
        break;
      }
    }
  }

  return {
    years: years,
    months: months,
    days: days,
  };
};

export const isLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const findFirstAndLastWeekdayDates = (
  startDate,
  endDate,
  selectedWeekday
) => {
  const firstDate = new Date(startDate || '');
  const lastDate = new Date(endDate || '');

  // Find the first date
  if (firstDate) {
    while (getDay(firstDate) !== selectedWeekday) {
      firstDate.setDate(firstDate.getDate() + 1);
    }
  }

  if (lastDate) {
    // Find the last date
    while (getDay(lastDate) !== selectedWeekday) {
      lastDate.setDate(lastDate.getDate() - 1);
    }
  }

  return { firstDate, lastDate };
};

export const calculateDatesForWeekly = (formData) => {
  const { startDate, endDate, customInput } = formData;
  const startDateParsed = parseISO(startDate);
  const endDateParsed = parseISO(endDate);
  const selectedDay = getNextWeekdayIndices(customInput?.scheduledOn || []); // Assume the first selected day is the one we want to calculate
  // const selectedDayIndex = Number(selectedDay);
  const selectedDayIndices = selectedDay?.map(Number);

  let currentDay = startDateParsed;
  let count = 0;
  let firstDate = null;
  let lastDate = null;

  // // Check if the start date is the selected day and move to the next week
  // if (currentDay.getDay() === selectedDayIndex) {
  //   currentDay = addDays(currentDay, 7 * customInput?.jobInterval);
  // }

  // while (
  //   isBefore(currentDay, endDateParsed) ||
  //   isEqual(currentDay, endDateParsed)
  // ) {
  //   if (currentDay.getDay() === selectedDayIndex) {
  //     count++;
  //     if (!firstDate) {
  //       firstDate = format(currentDay, 'MM/dd/yyyy');
  //     }
  //     lastDate = format(currentDay, 'MM/dd/yyyy');
  //   }
  //   currentDay = addDays(currentDay, 1);
  //   if (currentDay.getDay() === 0) {
  //     currentDay = addDays(currentDay, 7 * (customInput?.jobInterval - 1));
  //   }

  // }

  // Iterate over each selected day
  for (const selectedDayIndex of selectedDayIndices) {
    while (
      isBefore(currentDay, endDateParsed) ||
      isEqual(currentDay, endDateParsed)
    ) {
      if (currentDay.getDay() === selectedDayIndex) {
        count++;
        if (!firstDate) {
          firstDate = format(currentDay, 'MM/dd/yyyy');
        }
        lastDate = format(currentDay, 'MM/dd/yyyy');
      }
      currentDay = addDays(currentDay, 1);
      if (currentDay.getDay() === 0) {
        currentDay = addDays(
          currentDay,
          7 * ((customInput?.jobInterval || 1) - 1)
        );
      }
    }

    // Reset currentDay to the start date for the next selected day
    currentDay = startDateParsed;
  }

  return {
    count: count || 0,
    firstDate: firstDate || '',
    lastDate: lastDate || '',
  };
};

export const getNextWeekdayIndices = (days) => {
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const indices = [];

  for (const day of days) {
    const dayIndex = weekdays.indexOf(day);
    if (dayIndex !== -1) {
      indices.push(dayIndex);
    }
  }

  return indices;
};

export const calculateDatesWithMonths = (formData) => {
  const { startDate, endDate, customInput } = formData;
  const startDateParsed = parseISO(startDate);
  const endDateParsed = parseISO(endDate);

  let currentDay = startDateParsed;
  let count = 0;
  let firstDate = null;
  let lastDate = null;

  while (
    isBefore(currentDay, endDateParsed) ||
    isEqual(currentDay, endDateParsed)
  ) {
    if (customInput?.scheduledOn?.includes(currentDay.getDate())) {
      if (!firstDate) {
        firstDate = format(currentDay, 'MM/dd/yyyy');
      }
      lastDate = format(currentDay, 'MM/dd/yyyy');
      count++;
    }

    currentDay = addDays(currentDay, 1);
    if (customInput?.jobInterval > 1 && currentDay.getDate() === 1) {
      currentDay = addMonths(currentDay, customInput?.jobInterval - 1);
    }
  }

  return {
    count: count || 0,
    firstDate: firstDate || '',
    lastDate: lastDate || '',
  };
};

export const convertTimeToISOString = (timeString, defaultDate) => {
  const combinedDateTimeString = `${defaultDate} ${timeString}`;

  const dateTime = new Date(combinedDateTimeString);

  if (isNaN(dateTime.getTime())) {
    console.error('Invalid date or time format');
    return null;
  }

  // Convert the Date object to the desired format (ISO string)
  const isoString = dateTime.toISOString();

  return isoString;
};

export const filterLeadsContacts = (leadContacts, getSingleTask) => {
  return leadContacts?.filter((contact) => {
    return !getSingleTask?.contacts?.some((ele) => ele.id === contact.id);
  });
};

export const quoteDetailBillingSection = (
  discount,
  discountType,
  tax,
  subTotal,
  depositType,
  deposit
) => {
  if (
    discount === 0 &&
    tax === 0 &&
    (discountType === '$' || discountType === '%') &&
    deposit >= 0
  ) {
    const deposit_value = parseFloat((subTotal / 100) * deposit).toFixed(2);
    return {
      discount: 0,
      tax: 0,
      total: parseFloat(subTotal).toFixed(2),
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else if (
    discount > 0 &&
    tax === 0 &&
    discountType === '$' &&
    subTotal > 0 &&
    deposit >= 0
  ) {
    const total_value = parseFloat(subTotal - discount).toFixed(2);
    const deposit_value = parseFloat((total_value / 100) * deposit).toFixed(2);
    return {
      discount: parseFloat(discount).toFixed(2),
      tax: 0,
      total: total_value,
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else if (
    discount > 0 &&
    tax === 0 &&
    discountType === '%' &&
    subTotal > 0 &&
    deposit >= 0
  ) {
    const discount_value = parseFloat((subTotal / 100) * discount).toFixed(2);
    const total_value = parseFloat(subTotal - discount_value).toFixed(2);
    const deposit_value = parseFloat((total_value / 100) * deposit).toFixed(2);
    return {
      discount: discount_value,
      tax: 0,
      total: total_value,
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else if (
    discount === 0 &&
    tax > 0 &&
    (discountType === '$' || discountType === '%') &&
    subTotal > 0 &&
    deposit >= 0
  ) {
    const tax_value = parseFloat((subTotal / 100) * tax).toFixed(2);
    const total_value = parseFloat(subTotal + (subTotal / 100) * tax).toFixed(
      2
    );
    const deposit_value = parseFloat((total_value / 100) * deposit).toFixed(2);
    return {
      discount: 0,
      tax: tax_value,
      total: total_value,
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else if (
    discount > 0 &&
    tax > 0 &&
    discountType === '$' &&
    subTotal > 0 &&
    deposit >= 0
  ) {
    const discount_add_value = parseFloat(subTotal - discount).toFixed(2);
    const tax_value = parseFloat((subTotal / 100) * tax).toFixed(2);
    const total_value = parseFloat(
      parseFloat(discount_add_value) + parseFloat(tax_value)
    ).toFixed(2);
    const deposit_value = parseFloat((total_value / 100) * deposit).toFixed(2);
    return {
      discount: parseFloat(discount).toFixed(2),
      tax: tax_value,
      total: total_value,
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else if (
    discount > 0 &&
    tax > 0 &&
    discountType === '%' &&
    subTotal > 0 &&
    deposit >= 0
  ) {
    const discount_add_value = parseFloat((subTotal / 100) * discount).toFixed(
      2
    );
    const tax_value = parseFloat((subTotal / 100) * tax).toFixed(2);
    const total_value = parseFloat(
      subTotal - discount_add_value + parseFloat(tax_value)
    ).toFixed(2);
    const deposit_value = parseFloat((total_value / 100) * deposit).toFixed(2);
    return {
      discount: discount_add_value,
      tax: tax_value,
      total: total_value,
      deposit:
        depositType === '$' ? parseFloat(deposit).toFixed(2) : deposit_value,
    };
  } else {
    return {
      discount: 0,
      tax: 0,
      total: 0,
      deposit: 0,
    };
  }
};

export const _recurringTypeData = (data, startDate) => {
  if (data?.years === 0 && data?.months === 0 && data?.days < 7) {
    return [
      {
        id: 1,
        label: 'Custom Schedule',
        value: 'custom',
      },
    ];
  } else if (
    data?.years === 0 &&
    data?.months === 0 &&
    data?.days >= 7 &&
    data?.days < 14
  ) {
    return [
      {
        id: 1,
        label: `Weekly on ${moment(new Date()).format('dddd')}`,
        value: 'weekly',
      },
      {
        id: 2,
        label: 'Custom Schedule',
        value: 'custom',
      },
    ];
  } else if (
    data?.years === 0 &&
    data?.months === 0 &&
    data?.days >= 14 &&
    data?.days < 30
  ) {
    return [
      {
        id: 1,
        label: `Weekly on ${moment(new Date()).format('dddd')}`,
        value: 'weekly',
      },
      {
        id: 2,
        label: `Every 2 weeks on ${moment(new Date()).format('dddd')}`,
        value: 'two-week',
      },
      {
        id: 3,
        label: 'Custom Schedule',
        value: 'custom',
      },
    ];
  } else if (data?.years >= 0 && data?.months >= 0 && data?.days > 29) {
    return [
      {
        id: 1,
        label: `Weekly on ${moment(new Date()).format('dddd')}`,
        value: 'weekly',
      },
      {
        id: 2,
        label: `Every 2 weeks on ${moment(new Date()).format('dddd')}`,
        value: 'two-week',
      },
      {
        id: 3,
        label: `Monthly on the ${new Date().getDate(
          startDate
        )} day of the month`,
        value: 'monthly',
      },
      {
        id: 4,
        label: 'Custom Schedule',
        value: 'custom',
      },
    ];
  } else {
    return [
      {
        id: 1,
        label: `Weekly on ${moment(new Date()).format('dddd')}`,
        value: 'weekly',
      },
      {
        id: 2,
        label: `Every 2 weeks on ${moment(new Date()).format('dddd')}`,
        value: 'two-week',
      },
      {
        id: 3,
        label: `Monthly on the ${new Date().getDate(
          startDate
        )} day of the month`,
        value: 'monthly',
      },
      {
        id: 4,
        label: 'Custom Schedule',
        value: 'custom',
      },
    ];
  }
};

export const _selectedFrequencyData = (data) => {
  if (data?.years === 0 && data?.months === 0 && data?.days < 7) {
    return [
      {
        id: 1,
        label: `Daily`,
        value: 'daily',
        freqData: 'days(s)',
      },
    ];
  } else if (
    data?.years === 0 &&
    data?.months === 0 &&
    data?.days >= 7 &&
    data?.days < 14
  ) {
    return [
      {
        id: 1,
        label: `Daily`,
        value: 'daily',
        freqData: 'days(s)',
      },
      {
        id: 2,
        label: `Weekly`,
        value: 'weekly',
        freqData: 'week(s)',
      },
    ];
  } else if (
    data?.years === 0 &&
    data?.months === 0 &&
    data?.days >= 14 &&
    data?.days < 30
  ) {
    return [
      {
        id: 1,
        label: `Daily`,
        value: 'daily',
        freqData: 'days(s)',
      },
      {
        id: 2,
        label: `Weekly`,
        value: 'weekly',
        freqData: 'week(s)',
      },
    ];
  } else if (
    data?.years === 0 &&
    ((data?.months === 0 && data?.days > 29) ||
      (data?.months > 0 && data?.days >= 0))
  ) {
    return [
      {
        id: 1,
        label: `Daily`,
        value: 'daily',
        freqData: 'days(s)',
      },
      {
        id: 2,
        label: `Weekly`,
        value: 'weekly',
        freqData: 'week(s)',
      },
      {
        id: 3,
        label: `Monthly`,
        value: 'monthly',
        freqData: 'month(s)',
      },
    ];
  } else if (data?.years >= 0 && data?.months >= 0 && data?.days >= 0) {
    return [
      {
        id: 1,
        label: `Daily`,
        value: 'daily',
        freqData: 'days(s)',
      },
      {
        id: 2,
        label: `Weekly`,
        value: 'weekly',
        freqData: 'week(s)',
      },
      {
        id: 3,
        label: `Monthly`,
        value: 'monthly',
        freqData: 'month(s)',
      },
      {
        id: 4,
        label: 'Yearly',
        value: 'yearly',
        freqData: 'year(s)',
      },
    ];
  }
  return [
    {
      id: 1,
      label: `Daily`,
      value: 'daily',
      freqData: 'days(s)',
    },
    {
      id: 2,
      label: `Weekly`,
      value: 'weekly',
      freqData: 'week(s)',
    },
    {
      id: 3,
      label: `Monthly`,
      value: 'monthly',
      freqData: 'month(s)',
    },
    {
      id: 4,
      label: 'Yearly',
      value: 'yearly',
      freqData: 'year(s)',
    },
  ];
};

export const capitalizeFirstLetter = (string) => {
  if (!string) return string;
  if (typeof string !== 'string') return string;
  const words = string.split(' ');

  const firstWord =
    words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();

  const restWords = words.slice(1).join(' ').toLowerCase();

  return `${firstWord} ${restWords}`.trim();
};

const useResponsiveBreakpoints = () => {
  const theme = useTheme();

  // Custom breakpoint for devices smaller than 360px
  const isXSMin = useMediaQuery('(max-width: 400px)');

  // Existing breakpoints
  const isXS = useMediaQuery(theme.breakpoints.only('xs'));
  const isSM = useMediaQuery(theme.breakpoints.up('sm')); // Small devices (600px and up)
  const isMD = useMediaQuery(theme.breakpoints.up('md')); // Medium devices (900px and up)
  const isLG = useMediaQuery(theme.breakpoints.up('lg')); // Large devices (1200px and up)
  const isXL = useMediaQuery(theme.breakpoints.up('xl')); // Extra large devices (1536px and up)

  return {
    isXSMin,
    isXS,
    isSM,
    isMD,
    isLG,
    isXL,
  };
};

export default useResponsiveBreakpoints;

export const addSpaceAfterComma = (inputString) => {
  if (typeof inputString !== 'string') {
    return inputString;
  }
  return inputString.replace(/,(?!\s)/g, ', ');
};

export const capitalizeUserNameInNotification = (notification) => {
  if(!notification) return '';
  const message = notification?.notificationMessage ? capitalizeFirstLetter(notification?.notificationMessage) : '';
  const { notificationType, notificationTitle } = notification;

  if (message && notificationType && notificationTitle) {
    const words = message?.split(' ');

    if (
      notificationTitle === 'Proposal Viewed' ||
      notificationTitle === 'Proposal Accepted' ||
      notificationTitle === 'Proposal Rejected'
    ) {
      for (let i = 0; i < words.length; i++) {
        if (words[i] === 'by' && i + 2 < words.length) {
          words[i + 1] =
            words[i + 1].charAt(0).toUpperCase() + words[i + 1].slice(1);
          words[i + 2] =
            words[i + 2].charAt(0).toUpperCase() + words[i + 2].slice(1);
        }
      }
      return words.join(' ');
    }

    if (
      notificationTitle === 'New Appointment Scheduled!' ||
       notificationTitle === 'A new Appointment Assigned'
    ) {
      for (let i = 0; i < words.length; i++) {
        if (words[i] === 'with' && i + 2 < words.length) {
          words[i + 1] =
            words[i + 1].charAt(0).toUpperCase() + words[i + 1].slice(1);
          words[i + 2] =
            words[i + 2].charAt(0).toUpperCase() + words[i + 2].slice(1);
        }
      }
      return words.join(' ');
    }
    if (
      notificationTitle === 'New Appointment' ||
      notificationTitle === 'Invoice Paid'
    ) {
      if (words.length >= 2) {
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      words[1] = words[1].charAt(0).toUpperCase() + words[1].slice(1);
      return words.join(' ');
      }
    }
  }
  return message || '';
};

export const capitalizeString = (inputString) => {
  if (!inputString) return inputString;
  if(typeof(inputString) !== 'string') return inputString;
  return inputString
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const appendImgProperty = (string) => {
  // Check if the string contains an <img> tag
  if (/<img\b[^>]*>/.test(string)) {
    const updatedString = string?.replace(
      /<img([^>]*?)>/,
      '<img$1 width="100px" height="100px">'
    );
    return updatedString;
  } else {
    return string;
  }
};

export const isBlankHtmlContent = (html) => {
  if (!html) return true;
  const strippedContent = html?.replace(/<[^>]*>/g, '').trim();
  return strippedContent === '';
};
