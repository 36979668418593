import * as constants from 'redux/HomeOwner/invoices/constants';
import moment from 'moment';
import * as utils from 'utils';

const initialState = {
  loadingInvoice: false,
  invoice: {},
  fetchingInvoice: false,
  invoices: {},
  loadingPdf: false,
  pdfLink: null,
  creatingPayment: false,
  stripeData: null,
  refetchingInvoice: false,
  loadingOffline: false,
  refectingOffline: false,
  updatingStatus: false,
};

export default function invoicesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_INVOICE_BY_ID_REQUEST:
      return {
        ...state,
        loadingInvoice: true,
        refetchingInvoice: false,
        refectingOffline: false,
      };

    case constants.GET_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingInvoice: false,
        invoice: payload,
      };

    case constants.GET_INVOICE_BY_ID_FAIL:
      return {
        ...state,
        loadingInvoice: false,
      };

    case constants.GET_ALL_INVOICES_REQUEST:
      return {
        ...state,
        fetchingInvoice: true,
        refetchingInvoice: false,
        refectingOffline: false,
      };

    case constants.GET_ALL_INVOICES_SUCCESS:
      return {
        ...state,
        fetchingInvoice: false,
        invoices: payload,
      };

    case constants.GET_ALL_INVOICES_FAIL:
      return {
        ...state,
        fetchingInvoice: false,
      };

    case constants.UPDATE_INVOICE_PAGE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          page: payload,
        },
      };

    case constants.GET_INVOICE_PDF_BY_ID_REQUEST:
      return {
        ...state,
        loadingPdf: true,
      };

    case constants.GET_INVOICE_PDF_BY_ID_SUCCESS:
      return {
        ...state,
        loadingPdf: false,
        pdfLink: payload,
      };

    case constants.GET_INVOICE_PDF_BY_ID_FAIL:
      return {
        ...state,
        loadingPdf: false,
      };

    case constants.CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST:
      return {
        ...state,
        creatingPayment: true,
        refetchingInvoice: false,
      };

    case constants.CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        creatingPayment: false,
        refetchingInvoice: true,
        stripeData: payload,
      };

    case constants.CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL:
      return {
        ...state,
        creatingPayment: false,
        refetchingInvoice: false,
      };

    case constants.CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST:
      return {
        ...state,
        loadingOffline: true,
        refectingOffline: false,
      };

    case constants.CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS:
      return {
        ...state,
        loadingOffline: false,
        refectingOffline: true,
      };

    case constants.CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL:
      return {
        ...state,
        loadingOffline: false,
        refetchingInvoice: false,
        refectingOffline: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_REQUEST:
      return {
        ...state,
        updatingStatus: true,
        refetchingInvoice: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_SUCCESS:
      return {
        ...state,
        updatingStatus: false,
      };

    case constants.UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_FAIL:
      return {
        ...state,
        updatingStatus: false,
      };

    case constants.RESET_INVOICE_BY_ID_REFETCH:
      return {
        ...state,
        refetch: false,
        loadingOffline: false,
        refetchingInvoice: false,
        refectingOffline: false,
      };

    default:
      return state;
  }
}
