import * as constants from 'redux/HomeOwner/auth/constants';

const initialState = {
  loadingHearAboutUs: true,
  hearAboutUs: [],
  checkingAuth: true,
  loading: false,
  registered: false,
  checkingVerification: true,
  verified: false,
  isAuth: false,
  ownerUser: null,
  refetch: false,
  forgotingPassword: false,
  changingPassword: false,
  updatingProfile: false,
  refetchProfile: false,
};

export default function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case constants.GET_REGISTER_HEAR_ABOUT_US_REQUEST:
      return {
        ...state,
        loadingHearAboutUs: true,
        refetchProfile: false,
      };

    case constants.GET_REGISTER_HEAR_ABOUT_US_SUCCESS:
      return {
        ...state,
        hearAboutUs: payload,
        loadingHearAboutUs: false,
      };

    case constants.GET_REGISTER_HEAR_ABOUT_US_FAIL:
      return {
        ...state,
        loadingHearAboutUs: false,
      };

    case constants.REGISTER_REQUEST:
    case constants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        registered: false,
      };

    case constants.REGISTER_SUCCESS:
    case constants.REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        registered: true,
      };

    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        ownerUser: payload,
      };

    case constants.LOGIN_FAIL:
    case constants.LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        checkingAuth: false,
      };

    case constants.ACCOUNT_VERIFY_REQUEST:
      return {
        ...state,
        checkingVerification: true,
      };

    case constants.ACCOUNT_VERIFY_SUCCESS:
      return {
        ...state,
        checkingVerification: false,
        verified: payload,
      };

    case constants.ACCOUNT_VERIFY_FAIL:
      return {
        ...state,
        checkingVerification: false,
      };

    case constants.GET_USER_REQUEST:
    case constants.LOGOUT_REQUEST:
      return {
        ...state,
        checkingAuth: true,
        refetchProfile: false,
      };

    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        checkingAuth: false,
        isAuth: true,
        ownerUser: payload,
      };

    case constants.GET_USER_FAIL:
      return {
        ...state,
        checkingAuth: false,
        isAuth: false,
        ownerUser: null,
      };

    case constants.FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        forgotingPassword: true,
      };

    case constants.FORGET_PASSWORD_SUCCESS:
    case constants.FORGET_PASSWORD_FAIL:
      return {
        ...state,
        refetch: true,
        forgotingPassword: false,
      };

    case constants.LOGOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: false,
        checkingAuth: false,
      };

    case constants.RESET_AUTH_REFETCH:
      return {
        ...state,
        refetch: false,
        registered: false,
      };

    case constants.CHANGE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        changingPassword: true,
        refetchProfile: false,
      };

    case constants.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changingPassword: false,
        refetch: true,
      };

    case constants.CHANGE_USER_PASSWORD_FAIL:
      return {
        ...state,
        changingPassword: false,
      };

    case constants.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        updatingProfile: true,
        refetchProfile: false,
      };

    case constants.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updatingProfile: false,
        refetchProfile: true,
        ownerUser: payload,
      };

    case constants.UPDATE_USER_PROFILE_FAIL:
      return {
        ...state,
        updatingProfile: false,
        refetchProfile: false,
      };

    default:
      return state;
  }
}
