//--------------GET ALL INVOICES-----------------

export const GET_ALL_INVOICES_REQUEST = 'GET_ALL_INVOICES_REQUEST';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_FAIL = 'GET_ALL_INVOICES_FAIL';

//---------------GET INVOICE BY ID REQUEST-------------------
export const GET_INVOICE_BY_ID_REQUEST = 'GET_INVOICE_BY_ID_REQUEST';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_FAIL = 'GET_INVOICE_BY_ID_FAIL';

//---------------GET INVOICE PDF BY ID REQUEST-------------------
export const GET_INVOICE_PDF_BY_ID_REQUEST = 'GET_INVOICE_PDF_BY_ID_REQUEST';
export const GET_INVOICE_PDF_BY_ID_SUCCESS = 'GET_INVOICE_PDF_BY_ID_SUCCESS';
export const GET_INVOICE_PDF_BY_ID_FAIL = 'GET_INVOICE_PDF_BY_ID_FAIL';

//---------------CREATE ONLINE PAYMENT FOR INVOICE BY ID REQUEST-------------------
export const CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST =
  'CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST';
export const CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS =
  'CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS';
export const CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL =
  'CREATE_ONLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL';

//---------------CREATE OFFLINE PAYMENT FOR INVOICE BY ID REQUEST-------------------
export const CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST =
  'CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_REQUEST';
export const CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS =
  'CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_SUCCESS';
export const CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL =
  'CREATE_OFFLINE_PAYMENT_FOR_INVOICE_BY_ID_FAIL';

//---------------UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API REQUEST-------------------
export const UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_REQUEST =
  'UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_REQUEST';
export const UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_SUCCESS =
  'UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_SUCCESS';
export const UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_FAIL =
  'UPDATE_STRIPE_PAYMENT_STATUS_FOR_INVOICE_BY_ID_API_FAIL';

//-------------UPDATE INVOICE PAGE--------------------
export const UPDATE_INVOICE_PAGE = 'UPDATE_INVOICE_PAGE';

//-------------RESET REFETCH INVOICE REQUEST----------------------

export const RESET_INVOICE_BY_ID_REFETCH = 'RESET_INVOICE_BY_ID_REFETCH';
