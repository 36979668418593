import React, { useState } from 'react';
import { useFormik } from 'formik';

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordIcon from 'assets/new-theme-icons/svg/password-icon.svg';
import TextField from 'components/TextField';
import Button from 'components/Button';
import PassswordOpenIcon from 'assets/new-theme-icons/svg/password_open.svg';
import PassswordCloseIcon from 'assets/new-theme-icons/svg/password_close.svg';
import validationSchema from 'pages/validations/profile/password';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import Logo from 'components/Logo';
import useResponsiveBreakpoints from 'utils';
const Form = ({ loading, handleSubmit }) => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { isSM } = useResponsiveBreakpoints();
  const handleToggleCurrentPasswordVisibility = () => {
    setShowCurrentPassword((showCurrentPassword) => !showCurrentPassword);
  };

  const handleToggleNewPasswordVisibility = () => {
    setShowNewPassword((showNewPassword) => !showNewPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        required
        id="currentPassword"
        type={showCurrentPassword ? 'text' : 'password'}
        name="currentPassword"
        label={'Current password'}
        margin="normal"
        autoComplete="off"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.currentPassword &&
          Boolean(formik.errors.currentPassword)
        }
        helperText={
          formik.touched.currentPassword && formik.errors.currentPassword
        }
        placeholder={`Enter current password...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                height={isSM ? 22 : 15}
                width={isSM ? 22 : 15}
                src={PasswordIcon}
                alt="PasswordIcon"
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleCurrentPasswordVisibility}>
                {showCurrentPassword ? (
                  <Logo
                    src={PassswordOpenIcon}
                    alt="Password open"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                ) : (
                  <Logo
                    src={PassswordCloseIcon}
                    alt="Password close"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        sx={{
          marginTop: '6px',
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <TextField
        fullWidth
        required
        id="newPassword"
        type={showNewPassword ? 'text' : 'password'}
        name="newPassword"
        label={'New password'}
        margin="normal"
        autoComplete="off"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
        helperText={formik.touched.newPassword && formik.errors.newPassword}
        placeholder={`Enter current password...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                height={isSM ? 22 : 15}
                width={isSM ? 22 : 15}
                src={PasswordIcon}
                alt="PasswordIcon"
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleNewPasswordVisibility}>
                {showNewPassword ? (
                  <Logo
                    src={PassswordOpenIcon}
                    alt="Password open"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                ) : (
                  <Logo
                    src={PassswordCloseIcon}
                    alt="Password close"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        sx={{
          marginTop: '6px',
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <TextField
        fullWidth
        required
        id="confirmPassword"
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        label={'Confirm password'}
        margin="normal"
        autoComplete="off"
        value={formik.values.confirmPassword}
        onChange={formik.handleChange}
        error={
          formik.touched.confirmPassword &&
          Boolean(formik.errors.confirmPassword)
        }
        helperText={
          formik.touched.confirmPassword && formik.errors.confirmPassword
        }
        placeholder={`Enter current password...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                height={isSM ? 22 : 15}
                width={isSM ? 22 : 15}
                src={PasswordIcon}
                alt="PasswordIcon"
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                {showConfirmPassword ? (
                  <Logo
                    src={PassswordOpenIcon}
                    alt="Password open"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                ) : (
                  <Logo
                    src={PassswordCloseIcon}
                    alt="Password close"
                    height={isSM ? 24 : 17}
                    width={isSM ? 24 : 17}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
          inputProps: { maxLength: 50 },
        }}
        sx={{
          marginTop: '6px',
        }}
        fontWeight={500}
        requiredColor={theme?.palette?.voilet?.main}
        labelColor={theme?.palette?.common?.main}
      />

      <Button
        fullWidth
        variant="contained"
        size="large"
        type="submit"
        loading={loading}
        sx={{
          background: theme?.palette?.darkBlue?.main,
          minWidth: 140,
          fontSize: 14,
          mt: 2,
        }}
      >
        Submit
      </Button>
    </form>
  );
};

export default Form;
