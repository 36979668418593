import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PublicRoute = ({ isAuth, children }) => {
  const location = useLocation();
  const userEmail = localStorage.getItem('userEmail');
  const reqId = localStorage.getItem('reqId');

  const queryString = window.location.search;

  const cleanedQueryString =
    queryString.indexOf('?') !== -1
      ? queryString.substring(queryString.indexOf('?') + 1)
      : queryString;

  const parts = cleanedQueryString.split('?');
  const finalQueryString = parts.join('&');
  const urlParams = new URLSearchParams(finalQueryString);

  const userEmailFromParams = urlParams.get('email');
  const reqIdFromParams = urlParams.get('id');

  if (isAuth) {
    return (
      <Navigate
        to={
          userEmail || userEmailFromParams
            ? reqId || reqIdFromParams
              ? `/appointment-request/customer/view-request/${
                  userEmail || userEmailFromParams
                }/${reqId || reqIdFromParams}`
              : `/appointment-request/customer/view-request/${
                  userEmail || userEmailFromParams
                }`
            : '/projects'
        }
        state={{ from: location }}
      />
    );
  }

  // if (isAuth) {
  //   return <Navigate to='/home' state={{ from: location }} />;
  // }

  return <>{children}</>;
};

export default PublicRoute;
