import * as constants from 'redux/notifications/constants';

const initialState = {
  loadingAll: false,
  notifyAll: {
    columns: [],
    page: 1,
    rows: [],
    totalCount: 0,
    hasMore: true,
  },
  updating: false,
  fetchUpdating: false,
};

export default function notificationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case constants.GET_ALL_USERS_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loadingAll: true,
        fetchUpdating: false,
      };

    case constants.GET_ALL_USERS_NOTIFICATIONS_SUCCESS:
      let modData = {
        columns: [],
        page: 1,
        rows: [],
        unreadCount: 0,
        hasMore: true,
        totalUnreadCount: 0,
        totalCount: 0,
      };
      if (state.notifyAll.rows.length == 0) {
        modData.columns = payload.columns;
        modData.count = payload.count;
        modData.page = payload.page;
        modData.rows = payload.rows;
        modData.unreadCount = payload.unreadCount;
        modData.hasMore = payload.rows?.length < 10 ? false : true;
        modData.totalCount = payload?.totalCount;
      }
      if (state.notifyAll.rows.length !== 0) {
        modData.columns = payload.columns;
        modData.count = payload.count;
        modData.page = payload.page;
        modData.rows = [...state.notifyAll.rows, ...payload.rows];
        modData.unreadCount = payload.unreadCount;
        modData.hasMore = payload.rows?.length < 10 ? false : true;
        modData.totalUnreadCount = payload.totalUnreadCount;
        modData.totalCount = payload.totalCount;
      }
      return {
        ...state,
        loadingAll: false,
        notifyAll: modData,
      };

    case constants.GET_ALL_USERS_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loadingAll: false,
      };

    case constants.UPDATE_USER_NOTIFICATION_REQUEST:
      return {
        ...state,
        updating: true,
        fetchUpdating: false,
      };

    case constants.UPDATE_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updating: false,
        fetchUpdating: true,
      };

    case constants.UPDATE_USER_NOTIFICATION_FAIL:
      return {
        ...state,
        updating: false,
        fetchUpdating: false,
      };

    case constants.UPDATE_USER_NOTIFY_PAGE:
      return {
        ...state,
        notifyAll: {
          ...state?.notifyAll,
          page: payload,
        },
      };

    case constants.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        loadingAll: false,
        notifyAll: {
          columns: [],
          page: 1,
          rows: [],
          totalCount: 0,
          hasMore: true,
        },
        updating: false,
        fetchUpdating: false,
      };

    default:
      return state;
  }
}

// case constants.GET_ALL_USERS_NOTIFICATIONS_SUCCESS:
// const { page, rows, count,unreadCount,pageSize,
//   columns    } = action.payload;

//   return {
//     ...state,
//     loadingAll: false,
//     notifyAll: {
//       ...state.notifyAll,
//       page,
//       count,
//       unreadCount,
//       pageSize,
//       columns,
//       rows: page === 1 ? rows : [...state.notifyAll.rows, ...rows],
//     },
//   };
