import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from 'components/Button';
import Spinner from 'components/Spinner';
import useResponsiveBreakpoints, {
  capitalizeFirstLetter,
  capitalizeUserNameInNotification,
  fetchChar,
  fromNow,
} from 'utils';
import { useDispatch } from 'react-redux';

import * as notifyActions from 'redux/notifications/actions';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

const NotifyListData = ({ ownerUser, notifyAll, ...props }) => {
  const dispatch = useDispatch();
  const [pageNo, setpageNo] = useState(1);
  const { isSM, isXSMin } = useResponsiveBreakpoints();
  const notify = useSelector((state) => state.notification.notifyAll.rows);
  const hasMore = useSelector((state) => state.notification.notifyAll.hasMore);
  // const handlePage = () => {
  //   dispatch(notifyActions.updateNotifyPage(page + 1));
  // };

  const fetchMoreData = async () => {
    setpageNo((prevPage) => {
      const nxtPage = prevPage + 1;
      setTimeout(() => {
        dispatch(notifyActions.getAllUsersNotification(nxtPage));
      }, 1000);
      return nxtPage;
    });
  };

  const uniqueIds = {};
  const uniqueArray = notify?.filter((obj) => {
    if (!uniqueIds[obj.id]) {
      uniqueIds[obj.id] = true;
      return true;
    }
    return false;
  });

  return (
    <Box className="main_notify_content">
      <Box className="lisiting_header" overflow={'hidden'}>
        {notify?.length > 0 ? (
          <InfiniteScroll
            dataLength={notify?.length}
            next={fetchMoreData}
            hasMore={hasMore}
            height={isXSMin ? 370 : isSM ? 400 : 550}
            loader={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '10px 0px',
                }}
              >
                <Spinner
                  isLoader
                  style={{ height: '20px', width: '20px' }}
                  className
                />
              </div>
            }
          >
            {' '}
            {uniqueArray?.map((item) => {
              let fullName = `${ownerUser?.firstName || 'No Data'} ${
                ownerUser?.lastName || 'No Data'
              }`;
              if (item?.status === true) {
                return (
                  <Box key={item?.id} className="head_main_div">
                    <Box className="notify_list_user">
                      {item?.sourceModel === 'Proposal' ? (
                        <Typography className="notify_user_name">
                          <MailOutlineIcon className="new_list_icon" />
                        </Typography>
                      ) : (
                        <Typography className="notify_user_name">
                          {fetchChar(fullName)}
                        </Typography>
                      )}
                    </Box>

                    <Box className="notify_list_content_head">
                      <Box
                        display={'flex'}
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            sm: 'row',
                          },
                          alignItems: {
                            xs: 'start !important',
                            sm: 'center',
                          },
                        }}
                      >
                        <Typography
                          className="req_head"
                          fontWeight={item?.status ? 500 : 600}
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {capitalizeFirstLetter(item?.notificationTitle)}
                        </Typography>
                        <Typography
                          className="req_duration"
                          fontWeight={item?.status ? 500 : 600}
                        >
                          ({fromNow(item?.createdAt)})
                        </Typography>
                      </Box>

                      <Box>
                        <Button onClick={(e) => props?.handleViewPage(e, item)}>
                          <Typography
                            className="all_req_desc"
                            fontWeight={item?.status ? 400 : 500}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: { xs: '13px', sm: '16px' },
                            }}
                          >
                            {capitalizeUserNameInNotification(item)}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={item?.id}
                    className="head_hover_main"
                    onClick={() => props?.handleClickView(item)}
                  >
                    <Box className="notify_list_user">
                      {item?.sourceModel === 'Proposal' ? (
                        <Typography className="notify_user_name">
                          <MailOutlineIcon className="new_list_icon" />
                        </Typography>
                      ) : (
                        <Typography className="notify_user_name">
                          {fetchChar(fullName)}
                        </Typography>
                      )}
                      {item?.status === false ? (
                        <Box className="unread_data"></Box>
                      ) : null}
                    </Box>

                    <Box className="notify_list_content_head">
                      <Box
                        display={'flex'}
                        sx={{
                          flexDirection: {
                            xs: 'column',
                            sm: 'row',
                          },
                          alignItems: {
                            xs: 'start !important',
                            sm: 'center',
                          },
                        }}
                      >
                        <Typography
                          className="req_head"
                          fontWeight={item?.status ? 500 : 600}
                        >
                          {capitalizeFirstLetter(item?.notificationTitle)}
                        </Typography>
                        <Typography
                          className="req_duration"
                          fontWeight={item?.status ? 500 : 600}
                        >
                          ({fromNow(item?.createdAt)})
                        </Typography>
                      </Box>

                      <Box>
                        <Button onClick={(e) => props?.handleViewPage(e, item)}>
                          <Typography
                            className="all_req_desc"
                            fontWeight={item?.status ? 400 : 500}
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: { xs: '13px', sm: '16px' },
                            }}
                          >
                            {capitalizeUserNameInNotification(item)}
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                );
              }
            })}{' '}
          </InfiniteScroll>
        ) : (
          <Typography
            textAlign={'center'}
            p={2}
            sx={{ fontSize: { xs: '13px', sm: '16px' } }}
            mb={'28px'}
          >
            No notifications are here !
          </Typography>
        )}

        {/* {arrData?.length > 10 && (
          <Box display={'flex'} justifyContent="center" mb={1}>
            <Button
              variant="outlined"
              sx={{
                minWidth: '100px',
                padding: '0px !important',
                background: 'none',
                border: 'none',
                borderRadius: '0px !important',
                fontSize: {
                  xs: '13px',
                  sm: '16px',
                },
                '&:hover': {
                  border: 'none',
                  backgroundColor: 'transparent',
                },
              }}
              type="button"
              // onClick={handlePage}
            >
              Load more
            </Button>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default NotifyListData;
