import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import PrivateLayout from 'components/PrivateLayout';
import { privateRoutes } from 'routes';

const PrivateRoute = ({ isAuth, children, handleOpenModal, ...props }) => {
  const location = useLocation();
  const userEmail = localStorage.getItem('userEmail');

  const sidebar = privateRoutes.filter((e) => !e?.hidden);
  const hidderRoute = privateRoutes?.filter((e) => e?.hidden);

  if (!isAuth) {
    return (
      <Navigate
        to={
          userEmail !== null
            ? `/login?request-appointment?email=${userEmail}`
            : '/login'
        }
        state={{ from: location }}
      />
    );
  }

  return (
    <PrivateLayout
      sidebar={sidebar}
      hidderRoute={hidderRoute}
      handleOpenModal={handleOpenModal}
      {...props}
    >
      {children}
    </PrivateLayout>
  );
};

export default PrivateRoute;
